import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import "./style.css"
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert"
import ComponentLogin from "./ComponentLogin";

const emails = ["username@gmail.com", "user02@gmail.com"];

function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        onClose(value);
    };

    const [err, setErr] = useState(null);
    const login = localStorage.getItem("user");
    const [affiliate, setAffiliate] = useState(null);
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    React.useEffect(() => {
        if (login) {
            navigate("/");
        } else {
            const urlParams = new URLSearchParams(window.location.search);
            const refParam = urlParams.get("ref");
            if (refParam) {
                setAffiliate(refParam);
            }
            if (localStorage.getItem("affiliate")) {
                setAffiliate(localStorage.getItem("affiliate"));
            }
        }
    }, []);
    const onSubmit = (data, e) => {
        e.preventDefault();
        const phoneNumberPattern = /^(0\d{9,10})$/;
        const regex = /^[a-zA-Z0-9]+$/;
        if (data.username.length < 6) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập tối thiểu 6 kí tự"
            });
            return;
        }
        if (data.username.length > 12) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập tối đa 12 kí tự"
            });
            return;
        }
        if (!regex.test(data.username)) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập không chứa khoảng trắng và ký tự đặc biệt"
            });
            return;
        }
        if (data.password.length < 6) {
            setError("password", {
                type: "minLength",
                message: "Mật khẩu tối thiểu 6 kí tự"
            });
            return;
        }
        if (data.password !== data.ippassword) {
            setError("ippassword", {
                type: "minLength",
                message: "Hai mật khẩu không khớp"
            });
            return;
        }
        if (!data.name) {
            /*setError("name", {
                type: "minLength",
                message: "Vui lòng nhập đầy đủ họ tên"
            });
            return;*/
            data.name = data.username;
        }
        if (!data.sdt) {
            setError("sdt", {
                type: "minLength",
                message: "Vui lòng nhập số điện thoại"
            });
            return;
        }
        if (!phoneNumberPattern.test(data.sdt)) {
            setError("sdt", {
                type: "minLength",
                message: "Định dạng số điện thoại không hợp lệ"
            });
            return;
        }
        if (data.paypwd.length < 6) {
            setError("paypwd", {
                type: "minLength",
                message: "Mật khẩu rút tiền tối thiểu 6 kí tự"
            });
            return;
        }
        let formData;
        if (affiliate == null) {
            formData = {
                username: data.username.toLowerCase(),
                password: data.password,
                code: data.code === "" ? "admin" : data.code,
                sdt: data.sdt,
                name: data.name,
                paypwd: data.paypwd
            };
        } else {
            formData = {
                username: data.username.toLowerCase(),
                password: data.password,
                code: affiliate,
                sdt: data.sdt,
                name: data.name,
                paypwd: data.paypwd
            };
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/register`, formData)
            .then((res) => {
                swal({
                    title: "Thông báo",
                    text: "Đăng ký thành công",
                    icon: "success",
                    buttons: "OK"
                }).then(() => navigate("/"));
            })
            .catch((err) => {
                setErr(err.response.data.message ? err.response.data.message : "Đã có lỗi xảy ra!");
            });
    };
    const [showPassword, setShowPassword] = React.useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const [showPassword1, setShowPassword1] = React.useState(false);
    const toggleShowPassword1 = () => {
        setShowPassword1(!showPassword1);
    };
    const [showPassword2, setShowPassword2] = React.useState(false);
    const toggleShowPassword2 = () => {
        setShowPassword2(!showPassword2);
    };
    const [isSignin, setIsSignin]= useState(false)    


    return (
        <Dialog PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }} onClose={handleClose} open={open}>
            <div
                role="dialog"
                aria-modal="true"
                aria-label="Đăng ký "
                className="el-dialog el-dialog--center vnDialog"
                style={{ width: "440px" }}
                bis_skin_checked={1}>
                <div className="el-dialog__header" bis_skin_checked={1}>
                    <span className="el-dialog__title">{isSignin ? "Đăng nhập" : "Đăng ký"} </span>
                    <button onClick={()=> {
                        handleClose()
                    }} type="button" aria-label="Close" className="el-dialog__headerbtn">
                     <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#fff"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                    </button>
                </div>
                {isSignin ? <ComponentLogin setIsSignin={setIsSignin} handleClose={handleClose} isSignin={isSignin} /> : 
                <div className="el-dialog__body" bis_skin_checked={1}>
                    <div data-v-037699ce className="formContainer" bis_skin_checked={1} onSubmit={handleSubmit(onSubmit)}>
                        <form data-v-037699ce className="el-form vnForm el-form--label-top">
                            <div data-v-037699ce className="el-form-item el-form-item--feedback" bis_skin_checked={1}>

                                <div className="el-form-item__content" bis_skin_checked={1}>
                                    <span data-v-037699ce style={{ float: "left" }}>
                                        Tài khoản
                                    </span>
                                    <div data-v-037699ce className="el-input el-input--prefix" bis_skin_checked={1}>
        
                                        <input {...register("username")} type="text" autoComplete="off" placeholder="Tài khoản" className="el-input__inner" />
                                        <span className="el-input__prefix">
                                            <i data-v-037699ce className="icon icon-account" />
            
                                        </span>
                                    </div>
                                    {errors.username ? <p>{errors.username.message}</p> : null}
    
                                </div>
                            </div>
                            <div data-v-037699ce className="el-form-item el-form-item--feedback" bis_skin_checked={1}>

                                <div className="el-form-item__content" bis_skin_checked={1}>
                                    <span data-v-037699ce style={{ float: "left" }}>
                                        mật khẩu
                                    </span>
                                    <div data-v-037699ce className="el-input el-input--prefix" bis_skin_checked={1}>
        
                                        <input type={showPassword ? "text" : "password"} {...register("password")} autoComplete="off" placeholder="6-15 ký tự chữ và số  " className="el-input__inner" />
                                        <span className="el-input__prefix">
                                            <i data-v-037699ce className="icon icon-keywords" />
            
                                        </span>
                                    </div>
                                    {errors.password ? <p>{errors.password.message}</p> : null}
    
                                </div>
                            </div>
                            <div data-v-037699ce className="el-form-item el-form-item--feedback" bis_skin_checked={1}>

                                <div className="el-form-item__content" bis_skin_checked={1}>
                                    <span data-v-037699ce style={{ float: "left" }}>
                                        Xác nhận mật khẩu{" "}
                                    </span>
                                    <div data-v-037699ce className="el-input el-input--prefix" bis_skin_checked={1}>
        
                                        <input type={showPassword1 ? "text" : "password"} {...register("ippassword")} autoComplete="off" placeholder="Xác nhận thêm lần nữa " className="el-input__inner" />
                                        <span className="el-input__prefix">
                                            <i data-v-037699ce className="icon icon-keywords" />
            
                                        </span>
                                    </div>
                                    {errors.ippassword ? <p>{errors.ippassword.message}</p> : null}
    
                                </div>
                            </div>
                            <div data-v-037699ce className="el-form-item el-form-item--feedback" bis_skin_checked={1}>

                                <div className="el-form-item__content" bis_skin_checked={1}>
                                    <span data-v-037699ce style={{ float: "left" }}>
                                        Số điện thoại{" "}
                                    </span>
                                    <div data-v-037699ce className="phoneInput el-input el-input--prefix" bis_skin_checked={1}>
        
                                        <input type="text" {...register("sdt")} autoComplete="off" placeholder="10 chữ số, ví dụ 0988888888  " className="el-input__inner" />
                                        <span className="el-input__prefix">
                                            <i data-v-037699ce className="icon icon-number" />
            
                                        </span>
                                    </div>
                                    {errors.sdt ? <p>{errors.sdt.message}</p> : null}
    
                                </div>
                            </div>
                            <div data-v-037699ce className="el-form-item el-form-item--feedback" bis_skin_checked={1}>

                                <div className="el-form-item__content" bis_skin_checked={1}>
                                    <span data-v-037699ce style={{ float: "left" }}>
                                        Mật khẩu rút tiền{" "}
                                    </span>
                                    <div data-v-037699ce className="el-input el-input--prefix" bis_skin_checked={1}>
        
                                        <input type={showPassword2 ? "text" : "password"} {...register("paypwd")} autoComplete="off" placeholder="Mật khẩu rút tiền" className="el-input__inner" />
                                        <span className="el-input__prefix">
                                            <i data-v-037699ce className="icon icon-rname" />
            
                                        </span>
                                    </div>
                                    {errors.paypwd ? <p>{errors.paypwd.message}</p> : null}
    
                                </div>
                            </div>
                            <div data-v-037699ce className="el-form-item el-form-item--feedback" style={{ display: "none" }} bis_skin_checked={1}>

                                <div className="el-form-item__content" bis_skin_checked={1}>
                                    <span data-v-037699ce style={{ float: "left" }}>
                                        Mã giới thiệu{" "}
                                    </span>
                                    <div data-v-037699ce className="el-input el-input--prefix" bis_skin_checked={1}>
        
                                        <input type="code" {...register("code")} autoComplete="off" placeholder="Vui lòng nhập mã mời" className="el-input__inner" />
                                        <span className="el-input__prefix">
                                            <i data-v-037699ce className="icon icon-yqm" />
            
                                        </span>
                                    </div>
                                    {errors.code ? <p>{errors.code.message}</p> : null}
    
                                </div>
                            </div>
                            {err ? <p>{err}</p> : null}
                            <p data-v-037699ce className="links">
                                <label data-v-037699ce className="el-checkbox">
                                    <span className="el-checkbox__input">
                                        <span className="el-checkbox__inner" />
                                        <input type="checkbox" aria-hidden="false" className="el-checkbox__original" defaultValue />
                                    </span>
                                    <span className="el-checkbox__label">
                                        Tôi đã 18 tuổi, đã đọc và đồng ý quy tắc cá <span data-v-037699ce> cược </span>
        
                                    </span>
                                </label>
                            </p>
                            <div className="el-dialog__footer" bis_skin_checked={1}>
                    <span data-v-037699ce className="dialog-footer">
                        <button data-v-037699ce type="submit" className="el-button loginBtn el-button--primary isUserRulesAgree">
                            <span>Đăng ký </span>
                        </button>
                        <a onClick={()=> {setIsSignin(true)}} data-v-037699ce href="#" className="loginDialogShow">
                            Đã có tài khoản? Đăng nhập ngay
                        </a>
                    </span>
                </div>
                        </form>
                    </div>
                </div>
                }
                
            </div>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired
};

export default function DialogRegister() {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(emails[1]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };

    return (
        <React.Fragment>
           
            <Link to="/register" className="btn btn-register" onClick={(e)=> {
                e.preventDefault()
                handleClickOpen()
            }}>
                                                                Đăng ký
                                                            </Link>
            <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
        </React.Fragment>
    );
}
