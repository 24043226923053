import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import "./game.css";
import { Link, useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";

function Sports() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    function launchGame(params) {
        if (!localStorage.getItem("profile")) {
            swal("Thông báo", "Vui lòng đăng nhập", "info");
        }
        if (loading == true) return;
        const form = {
            ...params,
            mobile: isMobileDevice == true ? 1 : 0
        };
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/Seamless/LaunchGame`, form)
            .then((res) => {
                if (res.data.data.ErrorCode == 0) {
                    window.location.href = res.data.data.Url;
                } else {
                    swal("Sảnh đang bảo trì", "Vui lòng chọn sảnh khác", "error");
                }
                setLoading(false);
            })
            .catch((err) => {
                swal("Đã có lỗi xảy ra", err.response.data.message != "internal server error" ? err.response.data.message : "Vui lòng đăng xuất và đăng nhập lại", "error").then(
                    () => navigate("/login")
                );
                setLoading(false);
            });
    }

    return (
        <>
            {loading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <Box mt={3}>
                <div className="container">
                    <div className="banner-game" style={{ borderRadius: 10, overflow: "hidden" }}>
                        <img src={require("../../image/sports.jpg")} />
                    </div>
                    <Box mt={3}>
                        <Grid container spacing={4}>
                            <Grid item xs={4} onClick={() => launchGame({ productid: "1036", gametype: "3" })}>
                                <li data-v-6a002a33 data-v-17e847fd className="third-game-sport">
                                    <div data-v-6a002a33 className="sportsItem" bis_skin_checked={1}>
                                        <div data-v-6a002a33 bis_skin_checked={1}>
                                            <div data-v-6a002a33 className="row-l" bis_skin_checked={1}>
                                                <div data-v-6a002a33 className="platLogo" bis_skin_checked={1}>
                                                    <img
                                                        data-v-6a002a33
                                                        src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/fbsports.png?v=1.8.22"
                                                        alt=""
                                                    />
                                                </div>
                                                <p data-v-6a002a33 className="playCode">
                                                    FB SPORTS
                                                </p>
                                                <div data-v-6a002a33 className="playRebateWp" bis_skin_checked={1}>
                                                    <span data-v-6a002a33 className="playRebate">
                                                        Phí hoàn tối đa 1.5%
                                                    </span>
                                                </div>
                                                <div data-v-6a002a33 className="playButton" bis_skin_checked={1}>
                                                    Vào trò chơi
                                                </div>
                                            </div>
                                            <div data-v-6a002a33 className="game-img-athlete" bis_skin_checked={1}>
                                                <img
                                                    data-v-6a002a33
                                                    src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/sports/athlete/fbsports.png?v=1.8.22"
                                                    width="100%"
                                                    height="100%"
                                                    alt=""
                                                    className="athlete"
                                                />
                                            </div>
                                        </div>
                                        {/**/}
                                    </div>
                                </li>
                            </Grid>
                            <Grid item xs={4} onClick={() => launchGame({ productid: "1046", gametype: "3" })}>
                                <li data-v-6a002a33 data-v-17e847fd className="third-game-sport">
                                    <div data-v-6a002a33 className="sportsItem" bis_skin_checked={1}>
                                        <div data-v-6a002a33 className="ani-container" style={{ textAlign: "left", display: "none" }} bis_skin_checked={1}>
                                            <canvas width={450} height={254} style={{ backgroundColor: "transparent" }} />
                                        </div>
                                        <div data-v-6a002a33 bis_skin_checked={1}>
                                            <div data-v-6a002a33 className="row-l" bis_skin_checked={1}>
                                                <div data-v-6a002a33 className="platLogo" bis_skin_checked={1}>
                                                    <img data-v-6a002a33 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/saba.png?v=1.8.22" alt="" />
                                                </div>
                                                <p data-v-6a002a33 className="playCode">
                                                    SABA Sports
                                                </p>
                                                <div data-v-6a002a33 className="playRebateWp" bis_skin_checked={1}>
                                                    <span data-v-6a002a33 className="playRebate">
                                                        Phí hoàn tối đa 1.5%
                                                    </span>
                                                </div>
                                                <div data-v-6a002a33 className="playButton" bis_skin_checked={1}>
                                                    Vào trò chơi
                                                </div>
                                            </div>
                                            <div data-v-6a002a33 className="game-img-athlete" bis_skin_checked={1}>
                                                <img
                                                    data-v-6a002a33
                                                    src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/sports/athlete/sabasports.png?v=1.8.22"
                                                    width="100%"
                                                    height="100%"
                                                    alt=""
                                                    className="athlete"
                                                />
                                            </div>
                                        </div>
                                        {/**/}
                                    </div>
                                </li>
                            </Grid>
                            <Grid item xs={4} onClick={() => launchGame({ productid: "1078", gametype: "3" })}>
                                <li data-v-6a002a33 data-v-17e847fd className="third-game-sport">
                                    <div data-v-6a002a33 className="sportsItem" bis_skin_checked={1}>
                                        <div data-v-6a002a33 className="ani-container" style={{ textAlign: "left" }} bis_skin_checked={1}>
                                            <canvas width={450} height={254} style={{ backgroundColor: "transparent" }} />
                                        </div>
                                        <div data-v-6a002a33 bis_skin_checked={1}>
                                            <div data-v-6a002a33 className="row-l" bis_skin_checked={1}>
                                                <div data-v-6a002a33 className="platLogo" bis_skin_checked={1}>
                                                    <img data-v-6a002a33 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/cmd.png?v=1.8.22" alt="" />
                                                </div>
                                                <p data-v-6a002a33 className="playCode">
                                                    CMD THỂ THAO
                                                </p>
                                                <div data-v-6a002a33 className="playRebateWp" bis_skin_checked={1}>
                                                    <span data-v-6a002a33 className="playRebate">
                                                        Phí hoàn tối đa 1.5%
                                                    </span>
                                                </div>
                                                <div data-v-6a002a33 className="playButton" bis_skin_checked={1}>
                                                    Vào trò chơi
                                                </div>
                                            </div>
                                            <div data-v-6a002a33 className="game-img-athlete" bis_skin_checked={1}>
                                                <img
                                                    data-v-6a002a33
                                                    src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/sports/athlete/cmd.png?v=1.8.22"
                                                    width="100%"
                                                    height="100%"
                                                    alt=""
                                                    className="athlete showAni"
                                                />
                                            </div>
                                        </div>
                                        {/**/}
                                    </div>
                                </li>
                            </Grid>
                            <Grid item xs={4} onClick={() => launchGame({ productid: "1012", gametype: "3" })}>
                                <li data-v-6a002a33 data-v-17e847fd className="third-game-sport">
                                    <div data-v-6a002a33 className="sportsItem" bis_skin_checked={1}>
                                        <div data-v-6a002a33 className="ani-container" style={{ textAlign: "left" }} bis_skin_checked={1}>
                                            <canvas width={450} height={254} style={{ backgroundColor: "transparent" }} />
                                        </div>
                                        <div data-v-6a002a33 bis_skin_checked={1}>
                                            <div data-v-6a002a33 className="row-l" bis_skin_checked={1}>
                                                <div data-v-6a002a33 className="platLogo" bis_skin_checked={1}>
                                                    <img data-v-6a002a33 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/sbo.png?v=1.8.22" alt="" />
                                                </div>
                                                <p data-v-6a002a33 className="playCode">
                                                    Sports Book
                                                </p>
                                                <div data-v-6a002a33 className="playRebateWp" bis_skin_checked={1}>
                                                    <span data-v-6a002a33 className="playRebate">
                                                        Phí hoàn tối đa 1.5%
                                                    </span>
                                                </div>
                                                <div data-v-6a002a33 className="playButton" bis_skin_checked={1}>
                                                    Vào trò chơi
                                                </div>
                                            </div>
                                            <div data-v-6a002a33 className="game-img-athlete" bis_skin_checked={1}>
                                                <img
                                                    data-v-6a002a33
                                                    src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/sports/athlete/sportsbook.png?v=1.8.22"
                                                    width="100%"
                                                    height="100%"
                                                    alt=""
                                                    className="athlete showAni"
                                                />
                                            </div>
                                        </div>
                                        {/**/}
                                    </div>
                                </li>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Box>
        </>
    );
}

export default Sports;
