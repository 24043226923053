import axios from "axios";
import { useEffect, useState } from "react";
import Header from "../../components/Header";
import swal from "sweetalert";
import { GetNameChoose } from "../../../funcUtils";
import "./kubet.css";

function Kubet() {
    const [isVisible, setVisible] = useState(null);
    const [bet, setBet] = useState(null);
    const [profile, setProfile] = useState(null);
    const [historyGame, setHistoryGame] = useState(null);
    const [second, setSecond] = useState(20);
    const [minute, setMinute] = useState(0);
    const [start, setStart] = useState(false);
    const [dulieunhap, setDulieunhap] = useState(new Date());
    const [update, setUpdate] = useState(0);
    const date = new Date();
    const [current, setCurrent] = useState(null);
    const currentMinute = date.getMinutes();
    const currentSecond = date.getSeconds();
    const [total, setTotal] = useState(null);
    const [total2, setTotal2] = useState(null);
    const [setting, setSetting] = useState(null);
    const [isShow, setShow] = useState(false);
    const [ls, setLs] = useState(null);
    const [newMoney, setNewMoney] = useState(null);
    const [message, setMessage] = useState(null);
    const [share, setShare] = useState(false);
    const [choose, setChoose] = useState([]);

    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    const [activeOption, setActiveOption] = useState(null);

    const handleOptionClick = (option) => {
        setActiveOption(option);
        setNewMoney(Number(Number(option) * 1000).toLocaleString("vi-VN"));
    };
    function getProfile() {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
            setProfile(res.data.data);
        });
    }
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
            setProfile(res.data.data);
        });
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            setSetting(res.data.data[0]);
        });
        axios.get(`${process.env.REACT_APP_API_URL}/kubet/get`).then((res) => {
            setBet(res.data.data);
            setDulieunhap(new Date(res.data.data.createdAt));
            setStart(true);
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/kubet/getallbet`, {})
            .then((res) => {
                setTotal(res.data.data);
                setTotal2(res.data.data);
            })
            .catch(() => setTotal(null));
        getHistoryBet();
    }, []);
    useEffect(() => {
        const timer = setInterval(() => {
            if (Math.floor(20 - (new Date() - dulieunhap) / 1000) < 0) {
                axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
                    setProfile(res.data.data);
                });
                axios.get(`${process.env.REACT_APP_API_URL}/kubet/get`).then((res) => {
                    setBet(res.data.data);
                    setDulieunhap(new Date(res.data.data.createdAt));
                    if (res.data.data.status == "end") {
                        getProfile();
                        getHistoryBet();
                    }
                    if (res.data.data.result == "start") {
                        const date = new Date(res.data.data.updatedAt);
                        const date0 = new Date();
                        if (date0 - date < 60000) {
                            axios.get(`${process.env.REACT_APP_API_URL}/kubet/getcurrent`).then((res) => {
                                setCurrent(res.data.data);
                            });
                        } else {
                            setCurrent(null);
                        }
                    }
                });
                axios
                    .get(`${process.env.REACT_APP_API_URL}/kubet/getallbet`, {})
                    .then((res) => {
                        setTotal(res.data.data);
                    })
                    .catch(() => setTotal(null));
                getHistoryBet();
            }
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, [dulieunhap]);
    useEffect(() => {
        let curTime_second = Math.floor(20 - (date - dulieunhap) / 1000);

        let myTimeout;

        if (currentMinute === dulieunhap.getMinutes() && currentSecond === dulieunhap.getSeconds()) {
            setStart(true);
            setSecond(second - 1);
            return () => {
                clearTimeout(myTimeout);
            };
        } else if (curTime_second < 20 && curTime_second >= 0) {
            setSecond(curTime_second);
            setMinute((curTime_second - (curTime_second % 60)) / 60);
            setStart(true);
            return () => {
                clearTimeout(myTimeout);
            };
        } else {
            //cập nhật thời gian hiện tại 0.5s/lần
            myTimeout = setTimeout(() => {
                setUpdate(update + 1);
            }, 500);
        }
    }, [update, dulieunhap]);

    useEffect(() => {
        let curTime_second = Math.floor(20 - (date - dulieunhap) / 1000);
        let myTimeout = 0;
        if (start) {
            setSecond(curTime_second);
            setMinute(Math.floor(curTime_second / 60));

            if (curTime_second > 20 || curTime_second <= 0) {
                setStart(false);
                setMinute(0);
                setSecond(20);
                return () => {
                    clearTimeout(myTimeout);
                };
            }
            myTimeout = setTimeout(() => {
                setSecond(second - 1);
            }, 1000);
        }
        return () => {
            clearTimeout(myTimeout);
        };
    }, [second, start, dulieunhap]);

    function onChoose(num) {
        if ((choose.includes("6") && num === "5") || (choose.includes("5") && num === "6")) {
            //swal("Thông báo", "Không được phép đặt 2 cửa", "warning");
            //return;
        }
        if (choose.includes(num)) {
            setChoose(choose.filter((item) => item !== num));
        } else {
            setChoose([...choose, num]);
        }
    }
    const onSubmit = (e) => {
        e.preventDefault();
        if (bet?.status != "start") {
            swal("Thông báo", "Đã hết thời gian cược, vui lòng chờ phiên tiếp theo.", "info");
            return;
        }
        if (newMoney == 0 || newMoney == null) {
            swal("Thất bại", "Bạn chưa nhập tiền", "error");
            return;
        }
        const formData = {
            result: choose,
            id: bet._id,
            money: Number(newMoney.replaceAll(".", "").replaceAll(",", ""))
        };
        if (Number(newMoney.replaceAll(".", "").replaceAll(",", "")) > profile.money) {
            swal("Thất bại", "Số dư không đủ", "error");
            return;
        }
        if (!choose) {
            swal("Thất bại", "Bạn chưa lựa chọn", "error");
            return;
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/history/choosekubet`, formData)
            .then((res) => {
                swal("Đặt cược thành công", `${GetNameChoose(choose, null, "Live")}`, "success");
                setChoose(null);
                getHistoryBet();
                getProfile();
            })
            .catch((err) => swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error"));
    };
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }

    const [activeTab, setActiveTab] = useState("tab1");
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        getHistoryBet();
    };

    function getHistoryBet() {
        axios
            .get(`${process.env.REACT_APP_API_URL}/history/historyus`, { params: { keyword: "kubet" } })
            .then((res) => {
                setHistoryGame(res.data.data);
                getProfile();
            })
            .catch((err) => function () {});
    }
    return (
        <>
            <div>
                <div className="main xd">
                    <div className="box-bg-game">
                        <div className="container" style={{ maxWidth: "1000px" }}>
                            <div className="streaming">
                                <iframe src="https://system32-cloudfare-356783752985678522.monster/kubet2.html" loading="eager"></iframe>
                            </div>
                        </div>
                        <div className="bg-game" style={{ height: "100px" }}>
                            <div className="timedg">
                                {bet?.status == "start" ? (
                                    <>
                                        <div
                                            data-vadac70=""
                                            className="v_circle taste_lottery_countdown xodi  v_circle_show v_circle_PC"
                                            style={{ transform: "scale(0.9)", position: "unset" }}>
                                            <div data-vadac70="" className="mask half">
                                                <div data-vadac70="" className="fill fix" style={{ transform: `rotate(${second}deg)` }}></div>
                                            </div>
                                            <div data-vadac70="" flex="main:center cross:center" className="pv">
                                                <span data-vadac70="" className="progress">
                                                    {second}
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <span>Bắt đầu đặt cược</span> <br />
                                            <span style={{ opacity: "0.7" }}>Phiên {bet?.id_bet ? bet?.id_bet : "000"}</span>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div>
                                            <img src={require("./SV388_logo.png")} style={{ height: "40px" }} />
                                        </div>
                                        {bet?.status == "stop" ? (
                                            <div>
                                                <span>Dừng đặt cược</span> <br />
                                                <span style={{ opacity: "0.7" }}>Phiên {bet?.id_bet ? bet?.id_bet : "000"}</span>
                                            </div>
                                        ) : (
                                            <div>
                                                {bet?.result ? (
                                                    <>
                                                        <span>Kết quả</span> <br />
                                                        <span style={{ opacity: "0.7" }}>{bet?.result}</span>
                                                    </>
                                                ) : null}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="choose-box-kubet" style={{ maxWidth: "800px", margin: "0 auto" }}>
                        {bet?.status == "start" ? (
                            <>
                                <div className={`button-choose-kubet vang ${choose == 1 ? "active" : ""}`} onClick={(e) => setChoose(1)}>
                                    <span>1:14</span>
                                    <div>
                                        {historyGame
                                            ?.filter((item) => item.state == 1 && item.status_bet == "Pending")
                                            .map((item) => item.money)
                                            .reduce((acc, curr) => acc + curr, 0)
                                            .toLocaleString("vi-VN")}
                                    </div>
                                    <p>Con đôi</p>
                                </div>
                                <div className={`button-choose-kubet bdd ${choose == 2 ? "active" : ""}`} onClick={(e) => setChoose(2)}>
                                    <span>1:8</span>
                                    <div>
                                        {historyGame
                                            ?.filter((item) => item.state == 2 && item.status_bet == "Pending")
                                            .map((item) => item.money)
                                            .reduce((acc, curr) => acc + curr, 0)
                                            .toLocaleString("vi-VN")}
                                    </div>
                                    <p>Hòa</p>
                                </div>
                                <div className={`button-choose-kubet vang ${choose == 3 ? "active" : ""}`} onClick={(e) => setChoose(3)}>
                                    <span>1:14</span>
                                    <div>
                                        {historyGame
                                            ?.filter((item) => item.state == 3 && item.status_bet == "Pending")
                                            .map((item) => item.money)
                                            .reduce((acc, curr) => acc + curr, 0)
                                            .toLocaleString("vi-VN")}
                                    </div>
                                    <p>Cái đôi</p>
                                </div>
                                <div className={`button-choose-kubet xanh ${choose == 4 ? "active" : ""}`} onClick={(e) => setChoose(4)}>
                                    <span>1:1</span>
                                    <div>
                                        {historyGame
                                            ?.filter((item) => item.state == 4 && item.status_bet == "Pending")
                                            .map((item) => item.money)
                                            .reduce((acc, curr) => acc + curr, 0)
                                            .toLocaleString("vi-VN")}
                                    </div>
                                    <p>Người chơi</p>
                                </div>
                                <div className={`button-choose-kubet do ${choose == 5 ? "active" : ""}`} onClick={(e) => setChoose(5)}>
                                    <span>1:1</span>
                                    <div>
                                        {historyGame
                                            ?.filter((item) => item.state == 5 && item.status_bet == "Pending")
                                            .map((item) => item.money)
                                            .reduce((acc, curr) => acc + curr, 0)
                                            .toLocaleString("vi-VN")}
                                    </div>
                                    <p>Nhà cái</p>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="button-choose-kubet">
                                    <span>1:14</span>
                                    <div>
                                        {historyGame
                                            ?.filter((item) => item.state == 1 && item.status_bet == "Pending")
                                            .map((item) => item.money)
                                            .reduce((acc, curr) => acc + curr, 0)
                                            .toLocaleString("vi-VN")}
                                    </div>
                                    <p>Con đôi</p>
                                </div>
                                <div className="button-choose-kubet">
                                    <span>1:8</span>
                                    <div>
                                        {historyGame
                                            ?.filter((item) => item.state == 2 && item.status_bet == "Pending")
                                            .map((item) => item.money)
                                            .reduce((acc, curr) => acc + curr, 0)
                                            .toLocaleString("vi-VN")}
                                    </div>
                                    <p>Hòa</p>
                                </div>
                                <div className="button-choose-kubet">
                                    <span>1:14</span>
                                    <div>
                                        {historyGame
                                            ?.filter((item) => item.state == 3 && item.status_bet == "Pending")
                                            .map((item) => item.money)
                                            .reduce((acc, curr) => acc + curr, 0)
                                            .toLocaleString("vi-VN")}
                                    </div>
                                    <p>Cái đôi</p>
                                </div>
                                <div className="button-choose-kubet" style={{ width: "calc(50% - 5px)" }}>
                                    <span>1:1</span>
                                    <div>
                                        {historyGame
                                            ?.filter((item) => item.state == 4 && item.status_bet == "Pending")
                                            .map((item) => item.money)
                                            .reduce((acc, curr) => acc + curr, 0)
                                            .toLocaleString("vi-VN")}
                                    </div>
                                    <p>Người chơi</p>
                                </div>
                                <div className="button-choose-kubet" style={{ width: "calc(50% - 5px)" }}>
                                    <span>1:1</span>
                                    <div>
                                        {historyGame
                                            ?.filter((item) => item.state == 5 && item.status_bet == "Pending")
                                            .map((item) => item.money)
                                            .reduce((acc, curr) => acc + curr, 0)
                                            .toLocaleString("vi-VN")}
                                    </div>
                                    <p>Nhà cái</p>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="bet_taste_chips">
                        <div data-v-331b32c3="" className={`taste_chips_swiper_item ${activeOption === "5" ? "active" : ""}`} onClick={() => handleOptionClick("5")}>
                            <div data-v-331b32c3="" flex="main:center cross:center" className="taste_chip">
                                <div data-v-331b32c3="" className="taste_chip_base taste_chip_10">
                                    <div data-v-331b32c3="" className="item_chip_num">
                                        <span data-v-331b32c3="">5K</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-v-331b32c3="" className={`taste_chips_swiper_item ${activeOption === "10" ? "active" : ""}`} onClick={() => handleOptionClick("10")}>
                            <div data-v-331b32c3="" flex="main:center cross:center" className="taste_chip">
                                <div data-v-331b32c3="" flex="main:center cross:center" className="taste_chip_base taste_chip_25">
                                    <div data-v-331b32c3="" className="item_chip_num">
                                        <span data-v-331b32c3="">10K</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-v-331b32c3="" className={`taste_chips_swiper_item ${activeOption === "50" ? "active" : ""}`} onClick={() => handleOptionClick("50")}>
                            <div data-v-331b32c3="" className="taste_chip">
                                <div data-v-331b32c3="" className="taste_chip_base taste_chip_50">
                                    <div data-v-331b32c3="" className="item_chip_num">
                                        <span data-v-331b32c3="">50K</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-v-331b32c3="" className={`taste_chips_swiper_item ${activeOption === "100" ? "active" : ""}`} onClick={() => handleOptionClick("100")}>
                            <div data-v-331b32c3="" className="taste_chip">
                                <div data-v-331b32c3="" className="taste_chip_base taste_chip_100">
                                    <div data-v-331b32c3="" className="item_chip_num">
                                        <span data-v-331b32c3="">100K</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-v-331b32c3="" className={`taste_chips_swiper_item ${activeOption === "500" ? "active" : ""}`} onClick={() => handleOptionClick("500")}>
                            <div data-v-331b32c3="" className="taste_chip">
                                <div data-v-331b32c3="" className="taste_chip_base taste_chip_200">
                                    <div data-v-331b32c3="" className="item_chip_num">
                                        <span data-v-331b32c3="">500K</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-v-331b32c3="" className={`taste_chips_swiper_item ${activeOption === "1000" ? "active" : ""}`} onClick={() => handleOptionClick("1000")}>
                            <div data-v-331b32c3="" className="taste_chip">
                                <div data-v-331b32c3="" className="taste_chip_base taste_chip_500">
                                    <div data-v-331b32c3="" className="item_chip_num">
                                        <span data-v-331b32c3="">1000K</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-v-331b32c3="" className={`taste_chips_swiper_item ${activeOption === "5000" ? "active" : ""}`} onClick={() => handleOptionClick("5000")}>
                            <div data-v-331b32c3="" className="taste_chip">
                                <div data-v-331b32c3="" className="taste_chip_base taste_chip_5">
                                    <div data-v-331b32c3="" className="item_chip_num">
                                        <span data-v-331b32c3="">5000K</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bet-input-panel bet_panel_taste box-button-choose">
                        <div data-v-331b32c3="" flex="main:justify box:justify cross:center" className="bet_taste_info">
                            <button
                                data-v-331b32c3=""
                                className="bet_taste_reset"
                                onClick={() => {
                                    setActiveOption(null);
                                    setNewMoney(null);
                                }}>
                                Đặt lại
                            </button>
                            <div data-v-331b32c3="" className="bet_taste_text">
                                <div data-v-331b32c3="" flex="main:center cross:center" className="bet_taste_num vi">
                                    <span data-v-331b32c3="" className="bet_taste_text__common">
                                        Đã chọn
                                    </span>
                                    <span data-v-331b32c3="" className="bet_taste_text__protrude">
                                        {choose ? choose.length : 0}
                                    </span>
                                    <span data-v-331b32c3="" className="bet_taste_text__common">
                                        Lô
                                    </span>
                                </div>
                                <div data-v-331b32c3="" flex="cross:center" className="bet_taste_money">
                                    <input
                                        data-v-331b32c3=""
                                        flex-box="8"
                                        className="bet_taste_money_bet"
                                        min={1000}
                                        value={newMoney}
                                        onChange={(e) => setNewMoney(e.target.value)}
                                        onClick={() => setActiveOption(null)}
                                        onKeyUp={(e) => setNewMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString("vi-VN"))}
                                        name="money"
                                        type="text"
                                        placeholder="Nhập số tiền"
                                    />
                                </div>
                            </div>
                            <button data-v-331b32c3="" className="bet_taste_submit" type="submit" onClick={onSubmit}>
                                Đặt cược
                            </button>
                        </div>
                    </div>
                    <div className="history_game" style={{ right: "100px" }}>
                        <ul className="tab-navigation">
                            <li className={activeTab === "tab1" ? "active" : ""} onClick={() => handleTabClick("tab1")}>
                                Lịch sử trò chơi
                            </li>
                            <li className={activeTab === "tab2" ? "active" : ""} onClick={() => handleTabClick("tab2")}>
                                Lịch sử của tôi
                            </li>
                        </ul>

                        <div className="tab-content">
                            {activeTab === "tab1" && (
                                <div className="award_tb">
                                    <table>
                                        <tbody>
                                            {total2 &&
                                                total2.map((item, index) => (
                                                    <>
                                                        <tr key={index} style={{ textAlign: "center" }}>
                                                            <td>{item?.id_bet}</td>
                                                            <td
                                                                className="history_xucsac"
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "center"
                                                                }}>
                                                                {item.result}
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                            {activeTab === "tab2" && (
                                <>
                                    {historyGame != null ? (
                                        <div className="content-histories award_tb">
                                            {historyGame?.map((item, key) => (
                                                        <div
                                                            className="item_inner"
                                                            style={{ padding: "5px" }}
                                                            onClick={() => {
                                                                setLs(item);
                                                                setShow(true);
                                                            }}>
                                                            <div className="item_history">
                                                                <div className="title_item_history">
                                                                    <span className="sanh">{item.sanh}</span>
                                                                    <span
                                                                        className={`type_state text_status ${
                                                                            item.status_bet === "Pending" ? "pending" : item.status_bet === "Win" ? "win" : "lose"
                                                                        }`}>
                                                                        {item.status_bet}
                                                                    </span>
                                                                </div>
                                                                <div className="id_history_sanh">Phiên: {item?.id_bet?.id_bet ? item?.id_bet?.id_bet : item?.id_bet}</div>
                                                                <div className="id_history_sanh">{GetNameChoose(item.state, null, item.sanh)}</div>
                                                            </div>
                                                            <div className="money_history">
                                                                <span className="money" style={{ color: "#f2f2f2" }}>
                                                                    {Number(item.money).toLocaleString("vi-VN")}
                                                                </span>
                                                                <div className="time_choose">{formatDate(new Date(item.createdAt))}</div>
                                                            </div>
                                                        </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    {/*isShow === true && ls.status_bet !== "Pending" ? (
                        <>
                            <div className="modal" style={{ zIndex: "9999999" }}>
                                <div className="modaloverlay">
                                    <i className="ti-close closelogin"></i>
                                </div>
                                <div className="modalbody">
                                    <div>
                                        <div className="modalinner" style={{ padding: "10px 15px" }}>
                                            <div className="modalheader" style={{ padding: "10px 0 20px" }}>
                                                Chi tiết cược
                                            </div>
                                            {ls?.id_bet?.id_bet ? (
                                                <>
                                                    <div className="lsgd-table">
                                                        <div>Trò chơi</div>
                                                        <div>{ls.sanh}</div>
                                                    </div>
                                                    <div className="lsgd-table">
                                                        <div>Phiên</div>
                                                        <div>{ls?.id_bet?.id_bet}</div>
                                                    </div>
                                                    <div className="lsgd-table">
                                                        <div>Thời gian</div>
                                                        <div>{formatDate(new Date(ls.createdAt))}</div>
                                                    </div>
                                                    <div className="lsgd-table">
                                                        <div>Đặt cược</div>
                                                        <div>{GetNameChoose(ls.state, ls.type, ls.sanh)}</div>
                                                    </div>
                                                    <div className="lsgd-table">
                                                        <div>Tổng đặt</div>
                                                        <div>{Number(ls.money).toLocaleString("vi-VN")} </div>
                                                    </div>
                                                    <div className="lsgd-table">
                                                        <div>Tổng thắng</div>
                                                        <div>{Number(ls.moneythang).toLocaleString("vi-VN")} </div>
                                                    </div>
                                                    <h3 style={{ fontSize: "15px", margin: "20px 0 10px" }}>Kết quả phiên {ls?.id_bet?.id_bet}</h3>
                                                    <div
                                                        className="history_xucsac"
                                                        style={{
                                                            textAlign: "center",
                                                            fontSize: "13px"
                                                        }}>
                                                        {ls.id_bet.result.split(" ").map((item) => (
                                                            <div className={`a${item}`}></div>
                                                        ))}
                                                    </div>
                                                </>
                                            ) : null}
                                            <div>
                                                <div className="modalformcontrols">
                                                    <button
                                                        onClick={() => setShow(false)}
                                                        className="popup-close"
                                                        style={{
                                                            background: "#0064ff",
                                                            boxShadow: "none",
                                                            textShadow: "none"
                                                        }}>
                                                        ĐÓNG
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : null*/}
                </div>
            </div>
        </>
    );
}
export default Kubet;
