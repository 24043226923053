import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const TabNavigation = ({ id }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const handleTabClick = (tabName) => {
        navigate(tabName);
    };

    return (
        <div className="tab-navigation-game">
            <div className="tab-game-title">Bao lô</div>
            <div className={location.pathname === `/xsmt/lo2/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/lo2/${id}`)}>
                Lô 2 số
            </div>
            {/*<div className={location.pathname === `/xsmt/lo2dau/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/lo2dau/${id}`)}>
                Lô 2 số đầu
            </div>*/}
            <div className={location.pathname === `/xsmt/lo2so1k/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/lo2so1k/${id}`)}>
                Lô 2 số 1K
            </div>
            <div className={location.pathname === `/xsmt/lo3/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/lo3/${id}`)}>
                Lô 3 số
            </div>
            <div className={location.pathname === `/xsmt/lo4/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/lo4/${id}`)}>
                Lô 4 số
            </div>

            <div className="tab-game-title">Lô xiên</div>
            <div className={location.pathname === `/xsmt/loxien2/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/loxien2/${id}`)}>
                Lô xiên 2
            </div>
            <div className={location.pathname === `/xsmt/loxien3/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/loxien3/${id}`)}>
                Lô xiên 3
            </div>
            <div className={location.pathname === `/xsmt/loxien4/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/loxien4/${id}`)}>
                Lô xiên 4
            </div>

            <div className="tab-game-title">Đánh đề</div>
            <div className={location.pathname === `/xsmt/dedb/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/dedb/${id}`)}>
                Đề đặc biệt
            </div>
            <div className={location.pathname === `/xsmt/dedaudb/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/dedaudb/${id}`)}>
                Đề đầu đặc biệt
            </div>
            <div className={location.pathname === `/xsmt/dedauduoi/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/dedauduoi/${id}`)}>
                Đề đầu đuôi
            </div>
            <div className={location.pathname === `/xsmt/degiai8/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/degiai8/${id}`)}>
                Đề giải 8
            </div>
            {/*<div className={location.pathname === `/xsmt/de1/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/de1/${id}`)}>
                Đề giải nhất
            </div>
            <div className={location.pathname === `/xsmt/dedau1/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/dedau1/${id}`)}>
                Đề đầu giải nhất
            </div>*/}

            <div className="tab-game-title">Đầu đuôi</div>
            <div className={location.pathname === `/xsmt/dau/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/dau/${id}`)}>
                Đầu
            </div>
            <div className={location.pathname === `/xsmt/duoi/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/duoi/${id}`)}>
                Đuôi
            </div>

            <div className="tab-game-title">3 càng</div>
            <div className={location.pathname === `/xsmt/3cangdau/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/3cangdau/${id}`)}>
                3 càng đầu
            </div>
            <div className={location.pathname === `/xsmt/3cangdb/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/3cangdb/${id}`)}>
                3 càng đặc biệt
            </div>
            {/*<div className={location.pathname === `/xsmt/3cang1/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/3cang1/${id}`)}>
                3 càng giải nhất
            </div>*/}
            <div className={location.pathname === `/xsmt/3cangdauduoi/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/3cangdauduoi/${id}`)}>
                3 càng đầu đuôi
            </div>

            <div className="tab-game-title">4 càng</div>
            <div className={location.pathname === `/xsmt/4cangdb/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/4cangdb/${id}`)}>
                4 càng đặc biệt
            </div>

            <div className="tab-game-title">Lô trượt</div>
            <div className={location.pathname === `/xsmt/truotxien4/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/truotxien4/${id}`)}>
                Trượt xiên 4
            </div>
            <div className={location.pathname === `/xsmt/truotxien8/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/truotxien8/${id}`)}>
                Trượt xiên 8
            </div>
            <div className={location.pathname === `/xsmt/truotxien10/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/truotxien10/${id}`)}>
                Trượt xiên 10
            </div>

            {/*<div className="tab-game-title">Trò chơi thú vị</div>
            <div className={location.pathname === `/xsmt/lo2db/${id}` ? "active" : ""} onClick={() => handleTabClick(`/xsmt/lo2db/${id}`)}>
                Lô 2 số giải ĐB
            </div>*/}
        </div>
    );
};

export default TabNavigation;
