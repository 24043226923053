import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import "./promo.css";
import { Box, Grid } from "@mui/material";

function Promotion() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const [notifyItem, setNotifyItem] = useState();
    const [isShow, setShow] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
    }, []);
    return (
        <>
            <Box mt={3}>
                <section className="promo-section">
                    <div className="banner-game" style={{ borderRadius: 10, overflow: "hidden" }}>
                        <img src={"https://yscduxhccunurys.com/home/static/img/black.7522259c.png"} style={{ borderRadius: 10, overflow: "hidden" }} />
                    </div>
                    <div className="container" style={{ maxWidth: 1720 }}>
                        <Box mt={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <div data-v-73853d1a className="menu" bis_skin_checked={1}>
                                        <div data-v-73853d1a className="title" bis_skin_checked={1}>
                                            Khuyến mãi
                                        </div>
                                        <div data-v-73853d1a className="menu-list" bis_skin_checked={1}>
                                            <div data-v-73853d1a className="menu-item actived" bis_skin_checked={1}>
                                                <i data-v-73853d1a className="gift" /> Khuyến mãi{" "}
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid container spacing={3}>
                                        {notify.map((item, index) => (
                                            <>
                                                {index > 1 && item.title !== "marquee" && item.isShow === true ? (
                                                    <Grid
                                                        sx={{ background: "transparent" }}
                                                        key={index}
                                                        item
                                                        xs={4}
                                                        className="box-promo"
                                                        onClick={() => {
                                                            if (item.title.indexOf("{") !== 0) {
                                                                setNotifyItem(item);
                                                                setShow(true);
                                                            } else {
                                                                navigate(item.content.replace(/<\/?[^>]+(>|$)/g, ""));
                                                            }
                                                        }}>
                                                        <Box sx={{ background: "" }}>
                                                            <div className="box-promo-img" style={{ border: "2px solid #f0f0f0", borderRadius: 10 }}>
                                                                <img style={{ aspectRatio: 5 / 3, objectFit: "cover", borderRadius: 10 }} alt="" src={item.image} />
                                                            </div>
                                                            {/* <div className="box-promo-title">{item.title.replace("}", "").replace("{", "")}</div> */}
                                                        </Box>
                                                    </Grid>
                                                ) : null}
                                            </>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        {/* <div className="promo-title">Thông báo mới nhất</div> */}
                        {/* <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gridGap: "30px" }}></div> */}
                    </div>
                </section>
            </Box>
            {notifyItem != null && isShow ? (
                <div className="popup-backdrop">
                    <div className="popup-main">
                        <div className="popup-header">{notifyItem.title}</div>
                        <div className="popup-content">
                            {notifyItem.content.indexOf("{") != -1 && notifyItem.content.indexOf("{") != -1 ? (
                                <img
                                    src={notifyItem.content.substring(notifyItem.content.indexOf("{") + 1, notifyItem.content.indexOf("}"))}
                                    style={{ width: "100%", height: "auto" }}
                                />
                            ) : (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: notifyItem.content
                                    }}
                                />
                            )}
                        </div>
                        <button onClick={() => setShow(false)} className="popup-close">
                            Đóng
                        </button>
                    </div>
                </div>
            ) : null}
        </>
    );
}
export default Promotion;
