import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import "./game.css";
import { Link, useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";

function Pvp() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    function launchGame(params) {
        if (!localStorage.getItem("profile")) {
            swal("Thông báo", "Vui lòng đăng nhập", "info");
        }
        if (loading == true) return;
        const form = {
            ...params,
            mobile: isMobileDevice == true ? 1 : 0
        };
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/Seamless/LaunchGame`, form)
            .then((res) => {
                if (res.data.data.ErrorCode == 0) {
                    window.location.href = res.data.data.Url;
                } else {
                    swal("Sảnh đang bảo trì", "Vui lòng chọn sảnh khác", "error");
                }
                setLoading(false);
            })
            .catch((err) => {
                swal("Đã có lỗi xảy ra", err.response.data.message != "internal server error" ? err.response.data.message : "Vui lòng đăng xuất và đăng nhập lại", "error").then(
                    () => navigate("/login")
                );
                setLoading(false);
            });
    }
    return (
        <>
            {loading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <Box mt={3}>
                <div className="container">
                    <div className="banner-game" style={{ borderRadius: 10, overflow: "hidden" }}>
                        <img src={"https://yscduxhccunurys.com/home/static/img/chess_banner.049282be.png"} />
                    </div>
                    <Grid container spacing={3}>
                        <Grid item xs={6} >
                            <li onClick={() => launchGame({ productid: "1076", gametype: "7" })} data-v-a938c71c data-v-22d591fa className="third-game-chess fourFelx chess" providerlistobj="[object Object]">
                                <div data-v-a938c71c className="newThemeChessItem container-tran" bis_skin_checked={1}>
                                    <div data-v-a938c71c className="imgs" bis_skin_checked={1}>
                                        <img data-v-a938c71c src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/chess/intersperse_1/v8.png?v=1.8.22" alt="" className="intersperse_1" />
                                        <img data-v-a938c71c src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/chess/intersperse_2/v8.png?v=1.8.22" alt="" className="intersperse_2" />
                                        <img data-v-a938c71c src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/chess/croupier/v8.png?v=1.8.22" alt="" className="croupier tran-scale-105" />
                                    </div>
                                    <div data-v-a938c71c className="rt" bis_skin_checked={1}>
                                        <p data-v-a938c71c className="name">
                                            V8
                                        </p>
                                        {/**/}
                                        <span data-v-a938c71c className="playRebate">
                                            Phí hoàn tối đa 1.1%
                                        </span>
                                        <div data-v-a938c71c className="playButton tran-scale-105" bis_skin_checked={1}>
                                            {" "}
                                            Vào trò chơi{" "}
                                        </div>
                                    </div>
                                    {/**/}
                                </div>
                            </li>
                        </Grid>
                        <Grid item xs={6}>
                            <li onClick={() => launchGame({ productid: "1076", gametype: "7" })} data-v-a938c71c data-v-22d591fa className="third-game-chess fourFelx chess" providerlistobj="[object Object]">
                                <div data-v-a938c71c className="newThemeChessItem container-tran" bis_skin_checked={1}>
                                    <div data-v-a938c71c className="imgs" bis_skin_checked={1}>
                                        <img data-v-a938c71c src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/chess/intersperse_1/v8.png?v=1.8.22" alt="" className="intersperse_1" />
                                        <img data-v-a938c71c src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/chess/intersperse_2/v8.png?v=1.8.22" alt="" className="intersperse_2" />
                                        <img data-v-a938c71c src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/chess/croupier/rich88.png?v=1.8.22" alt="" className="croupier tran-scale-105" />
                                    </div>
                                    <div data-v-a938c71c className="rt" bis_skin_checked={1}>
                                        <p data-v-a938c71c className="name">
                                            V8
                                        </p>
                                        {/**/}
                                        <span data-v-a938c71c className="playRebate">
                                            Phí hoàn tối đa 1.1%
                                        </span>
                                        <div data-v-a938c71c className="playButton tran-scale-105" bis_skin_checked={1}>
                                            {" "}
                                            Vào trò chơi{" "}
                                        </div>
                                    </div>
                                    <div data-v-a938c71c className="ani-container" bis_skin_checked={1} style={{ textAlign: "left" }}>
                                        <canvas width={688} height={200} style={{ backgroundColor: "transparent" }} />
                                    </div>
                                    {/**/}
                                </div>
                            </li>
                        </Grid>
                    </Grid>
                    {/* <div className="menu-box-game" style={{ gridTemplateColumns: "1fr 1fr" }}>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/chess_sub_v8.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ productid: "1076", gametype: "7" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/gamebai_tp.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ productid: "1076", gametype: "7" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                    </div> */}
                </div>
            </Box>
        </>
    );
}
export default Pvp;
