import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import "./game.css";
import { Link, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

function Lottery() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [loading, setLoading] = useState(false);
    const date0 = new Date();
    const currentHour = date0.getHours();
    const [dateMT, setDateMT] = useState(date0.getDay());
    const [dataMT, setDataMT] = useState();
    const [imageMT, setImageMT] = useState([]);
    const [dateMN, setDateMN] = useState(date0.getDay());
    const [dataMN, setDataMN] = useState([]);
    const [imageMN, setImageMN] = useState([]);
    const [activeOption1, setActiveOption1] = useState(date0.getDay());
    const handleOptionClick1 = (option) => {
        setActiveOption1(option);
        setDateMT(option);
    };
    const [activeOption2, setActiveOption2] = useState(date0.getDay());
    const handleOptionClick2 = (option) => {
        setActiveOption2(option);
        setDateMN(option);
    };
    useEffect(() => {
        if (dateMT == 0) {
            if (currentHour < 18) {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            } else {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            }
        }
        if (dateMT == 1) {
            if (currentHour < 18) {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            } else {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            }
        }
        if (dateMT == 2) {
            if (currentHour < 18) {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            } else {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            }
        }
        if (dateMT == 3) {
            if (currentHour < 18) {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            } else {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            }
        }
        if (dateMT == 4) {
            if (currentHour < 18) {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            } else {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            }
        }
        if (dateMT == 5) {
            if (currentHour < 18) {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            } else {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            }
        }
        if (dateMT == 6) {
            if (currentHour < 18) {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            } else {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            }
        }
    }, [dateMT]);
    useEffect(() => {
        if (dateMN == 0) {
            if (currentHour < 17) {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            } else {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            }
        }
        if (dateMN == 1) {
            if (currentHour < 17) {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            } else {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            }
        }
        if (dateMN == 2) {
            if (currentHour < 17) {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            } else {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            }
        }
        if (dateMN == 3) {
            if (currentHour < 17) {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            } else {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            }
        }
        if (dateMN == 4) {
            if (currentHour < 17) {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            } else {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            }
        }
        if (dateMN == 5) {
            if (currentHour < 17) {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            } else {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            }
        }
        if (dateMN == 6) {
            if (currentHour < 17) {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            } else {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            }
        }
    }, [dateMN]);
    return (
        <>
            {loading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <Box mt={3}> 
                <div className="container">
                    <div className="banner-game">
                        <img src={require("../../image/lott.jpg")} style={{borderRadius: 10, overflow: "hidden"}} />
                    </div>
                    <div style={{ padding: "0 0 2rem" }}>
                        <div>
                            {dataMT && dataMN ? (
                                <>
                                    <div className="">
                                        <div className="section-title">
                                            <div className="title-gradient">Miền Bắc</div>
                                            <div className="select-box"></div>
                                        </div>
                                        <div className="list-game-xs">
                                            <Link to="/xsmb/lo2">
                                                <div className="item">
                                                    <img alt="" src={require(`../../images/xs3mien/mienbac.png`)} className="img-xs" style={{ height: "185px" }} />
                                                    <div className="dat-cuoc">Đặt cược</div>
                                                </div>
                                            </Link>
                                            <Link to="/xs75s/lo2">
                                                <div className="item">
                                                    <img alt="" src={require(`../../images/mb75s.png`)} className="img-xs" style={{ height: "185px" }} />
                                                    <div className="dat-cuoc">Đặt cược</div>
                                                </div>
                                            </Link>
                                            <Link to="/xs120s/lo2">
                                                <div className="item">
                                                    <img alt="" src={require(`../../images/st2p.png`)} className="img-xs" style={{ height: "185px" }} />
                                                    <div className="dat-cuoc">Đặt cược</div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="section-title">
                                            <div className="title-gradient">Miền Trung</div>
                                        </div>
                                        <ul className="tab-navigation tab-game tab-xoso">
                                            <li className={activeOption1 === 1 ? "active" : ""} onClick={() => handleOptionClick1(1)}>
                                                Thứ 2
                                            </li>
                                            <li className={activeOption1 === 2 ? "active" : ""} onClick={() => handleOptionClick1(2)}>
                                                Thứ 3
                                            </li>
                                            <li className={activeOption1 === 3 ? "active" : ""} onClick={() => handleOptionClick1(3)}>
                                                Thứ 4
                                            </li>
                                            <li className={activeOption1 === 4 ? "active" : ""} onClick={() => handleOptionClick1(4)}>
                                                Thứ 5
                                            </li>
                                            <li className={activeOption1 === 5 ? "active" : ""} onClick={() => handleOptionClick1(5)}>
                                                Thứ 6
                                            </li>
                                            <li className={activeOption1 === 6 ? "active" : ""} onClick={() => handleOptionClick1(6)}>
                                                Thứ 7
                                            </li>
                                            <li className={activeOption1 === 0 ? "active" : ""} onClick={() => handleOptionClick1(0)}>
                                                Chủ nhật
                                            </li>
                                        </ul>
                                        <div className="list-game-xs">
                                            {dataMT?.map((item, index) => (
                                                <>
                                                    <Link to={`/xsmt/lo2/${item}`} key={item}>
                                                        <div className="item">
                                                            <img alt="" src={require(`../../images/xs3mien/${imageMT[index]}.png`)} className="img-xs" />
                                                            <div className="dat-cuoc">Đặt cược</div>
                                                        </div>
                                                    </Link>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="section-title">
                                            <div className="title-gradient">Miền Nam</div>
                                        </div>
                                        <ul className="tab-navigation tab-game tab-xoso">
                                            <li className={activeOption2 === 1 ? "active" : ""} onClick={() => handleOptionClick2(1)}>
                                                Thứ 2
                                            </li>
                                            <li className={activeOption2 === 2 ? "active" : ""} onClick={() => handleOptionClick2(2)}>
                                                Thứ 3
                                            </li>
                                            <li className={activeOption2 === 3 ? "active" : ""} onClick={() => handleOptionClick2(3)}>
                                                Thứ 4
                                            </li>
                                            <li className={activeOption2 === 4 ? "active" : ""} onClick={() => handleOptionClick2(4)}>
                                                Thứ 5
                                            </li>
                                            <li className={activeOption2 === 5 ? "active" : ""} onClick={() => handleOptionClick2(5)}>
                                                Thứ 6
                                            </li>
                                            <li className={activeOption2 === 6 ? "active" : ""} onClick={() => handleOptionClick2(6)}>
                                                Thứ 7
                                            </li>
                                            <li className={activeOption2 === 0 ? "active" : ""} onClick={() => handleOptionClick2(0)}>
                                                Chủ nhật
                                            </li>
                                        </ul>
                                        <div className="list-game-xs">
                                            {dataMN?.map((item, index) => (
                                                <>
                                                    <Link to={`/xsmn/lo2/${item}`} key={item}>
                                                        <div className="item">
                                                            <img alt="" src={require(`../../images/xs3mien/${imageMN[index]}.png`)} className="img-xs" />
                                                            <div className="dat-cuoc">Đặt cược</div>
                                                        </div>
                                                    </Link>
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </div>
                    </div>
                </div>
            </Box>
        </>
    );
}
export default Lottery;
