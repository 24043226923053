import React from "react";
import "./megamenusport.css";
import axios from "axios"; // Thêm axios
import swal from "sweetalert"; // Thêm sweetalert để sử dụng swal

const MegamenuSport = (props) => {
    const launchGame = (params) => {
        if (!localStorage.getItem("profile")) {
            swal("Thông báo", "Vui lòng đăng nhập", "info");
            return;
        }
        const form = {
            ...params,
            mobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 1 : 0
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/Seamless/LaunchGame`, form)
            .then((res) => {
                if (res.data.data.ErrorCode === 0) {
                    window.location.href = res.data.data.Url;
                } else {
                    swal("Sảnh đang bảo trì", "Vui lòng chọn sảnh khác", "error");
                }
            })
            .catch((err) => {
                swal("Đã có lỗi xảy ra", err.response?.data?.message || "Vui lòng đăng xuất và đăng nhập lại", "error");
            });
    };

    return (
        <div data-v-1936baa7 className="realGamer sports" bis_skin_checked={1} style={{maxHeight: props?.maxHeight || 0}}>
            <ul data-v-1936baa7 className="even-in-last-row">
                <li data-v-1936baa7 className="gameItem last-row-item" onClick={() => launchGame({ productid: "1036", gametype: "3" })}>
                    <div data-v-12798650 data-v-1936baa7 className="navPlatItem sports" bis_skin_checked={1}>
                        <div data-v-12798650 className="cover" bis_skin_checked={1}>
                            <div data-v-12798650 className="btn-play" bis_skin_checked={1}>
                                <div data-v-12798650 className="icon-play" bis_skin_checked={1} />
                                <div data-v-12798650 className="btn-txt" bis_skin_checked={1}>
                                    PLAY NOW
                                </div>
                            </div>
                        </div>
                        <p data-v-12798650 className="line" />
                        <div data-v-12798650 className="name" bis_skin_checked={1}>
                            <p data-v-12798650> Phí hoàn tối đa 1.5% </p>
                        </div>
                        <div data-v-12798650 className="imgContainer sports" bis_skin_checked={1}>
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/sports/athlete/fbsports.png?v=1.8.22" alt="" className="main" />
                            <img data-v-12798650 src="static/img/sports_sod.c5c15af7.png" alt="" className="bot" />
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/fbsports.png?v=1.8.22" alt="" className="logo fb_sport" />
                        </div>
                    </div>
                </li>
                <li data-v-1936baa7 className="gameItem last-row-item" onClick={() => launchGame({ productid: "1046", gametype: "3" })}>
                    <div data-v-12798650 data-v-1936baa7 className="navPlatItem sports" bis_skin_checked={1}>
                        <div data-v-12798650 className="cover" bis_skin_checked={1}>
                            <div data-v-12798650 className="btn-play" bis_skin_checked={1}>
                                <div data-v-12798650 className="icon-play" bis_skin_checked={1} />
                                <div data-v-12798650 className="btn-txt" bis_skin_checked={1}>
                                    PLAY NOW
                                </div>
                            </div>
                        </div>
                        <p data-v-12798650 className="line" />
                        <div data-v-12798650 className="name" bis_skin_checked={1}>
                            <p data-v-12798650> Phí hoàn tối đa 1.5% </p>
                        </div>
                        <div data-v-12798650 className="imgContainer sports" bis_skin_checked={1}>
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/sports/athlete/sabasports.png?v=1.8.22" alt="" className="main" />
                            <img data-v-12798650 src="static/img/sports_sod.c5c15af7.png" alt="" className="bot" />
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/saba.png?v=1.8.22" alt="" className="logo sabasports" />
                        </div>
                    </div>
                </li>
                <li data-v-1936baa7 className="gameItem last-row-item" onClick={() => launchGame({ productid: "1078", gametype: "3" })}>
                    <div data-v-12798650 data-v-1936baa7 className="navPlatItem sports" bis_skin_checked={1}>
                        <div data-v-12798650 className="cover" bis_skin_checked={1}>
                            <div data-v-12798650 className="btn-play" bis_skin_checked={1}>
                                <div data-v-12798650 className="icon-play" bis_skin_checked={1} />
                                <div data-v-12798650 className="btn-txt" bis_skin_checked={1}>
                                    PLAY NOW
                                </div>
                            </div>
                        </div>
                        <p data-v-12798650 className="line" />
                        <div data-v-12798650 className="name" bis_skin_checked={1}>
                            <p data-v-12798650> Phí hoàn tối đa 1.5% </p>
                        </div>
                        <div data-v-12798650 className="imgContainer sports" bis_skin_checked={1}>
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/sports/athlete/cmd.png?v=1.8.22" alt="" className="main" />
                            <img data-v-12798650 src="static/img/sports_sod.c5c15af7.png" alt="" className="bot" />
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/cmd.png?v=1.8.22" alt="" className="logo cmd1" />
                        </div>
                    </div>
                </li>
                <li data-v-1936baa7 className="gameItem last-row-item" onClick={() => launchGame({ productid: "1012", gametype: "3" })}>
                    <div data-v-12798650 data-v-1936baa7 className="navPlatItem sports" bis_skin_checked={1}>
                        <div data-v-12798650 className="cover" bis_skin_checked={1}>
                            <div data-v-12798650 className="btn-play" bis_skin_checked={1}>
                                <div data-v-12798650 className="icon-play" bis_skin_checked={1} />
                                <div data-v-12798650 className="btn-txt" bis_skin_checked={1}>
                                    PLAY NOW
                                </div>
                            </div>
                        </div>
                        <p data-v-12798650 className="line" />
                        <div data-v-12798650 className="name" bis_skin_checked={1}>
                            <p data-v-12798650> Phí hoàn tối đa 1.5% </p>
                        </div>
                        <div data-v-12798650 className="imgContainer sports" bis_skin_checked={1}>
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/sports/athlete/sportsbook.png?v=1.8.22" alt="" className="main" />
                            <img data-v-12798650 src="static/img/sports_sod.c5c15af7.png" alt="" className="bot" />
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/sbo.png?v=1.8.22" alt="" className="logo sportsbook" />
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default MegamenuSport;
