import React, { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import "./megamenu.css";
import { Swiper, SwiperSlide } from "swiper/react";

const Megamenu = (props) => {
    const [loading, setLoading] = useState(false);

    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    function launchGame(params) {
        if (!localStorage.getItem("profile")) {
            swal("Thông báo", "Vui lòng đăng nhập", "info");
            return;
        }
        if (loading === true) return;

        const form = {
            ...params,
            mobile: isMobileDevice() ? 1 : 0,
        };

        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/Seamless/LaunchGame`, form)
            .then((res) => {
                if (res.data.data.ErrorCode === 0) {
                    window.location.href = res.data.data.Url;
                } else {
                    swal("Sảnh đang bảo trì", "Vui lòng chọn sảnh khác", "error");
                }
                setLoading(false);
            })
            .catch((err) => {
                swal(
                    "Đã có lỗi xảy ra",
                    err.response.data.message !== "internal server error"
                        ? err.response.data.message
                        : "Vui lòng đăng xuất và đăng nhập lại",
                    "error"
                );
                setLoading(false);
            });
    }

    return (
        <div data-v-1936baa7 data-v-34ed863c className="realGamerWrapper" bis_skin_checked={1}>
            <div data-v-1936baa7 className="realGamer realGamerSwiper" bis_skin_checked={1} style={{maxHeight: props?.maxHeight || 0}}>
                <div data-v-1936baa7 className="swiperContainer" bis_skin_checked={1}>
                    <Swiper navigation={true} slidesPerView={6}>
                        <SwiperSlide onClick={() => launchGame({ productid: "1022", gametype: "2" })}>
                            <div data-v-51c69319 data-v-1936baa7 className="navPlatItem navPlatItem-live" bis_skin_checked={1}>
                                <div data-v-51c69319 className="content content-live" bis_skin_checked={1}>
                                    <div data-v-51c69319 className="cover" bis_skin_checked={1}>
                                        <div data-v-51c69319 className="btn-play" bis_skin_checked={1}>
                                            <div data-v-51c69319 className="icon-play" bis_skin_checked={1} />
                                            <div data-v-51c69319 className="btn-txt" bis_skin_checked={1}>
                                                {" "}Bắt đầu chơi
                                            </div>
                                        </div>
                                    </div>
                                    <div data-v-51c69319 className="liveContainer" bis_skin_checked={1}>
                                        <div data-v-51c69319 className="top" bis_skin_checked={1}>
                                            <img
                                                data-v-51c69319
                                                src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/foreground/ae.png?v=1.8.22"
                                                alt=""
                                                className="foreground"
                                            />
                                            <img
                                                data-v-51c69319
                                                src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/croupier/ae.png?v=1.8.22"
                                                alt=""
                                                className="croupier"
                                            />
                                        </div>
                                        <div data-v-51c69319 className="bot" bis_skin_checked={1}>
                                            <div data-v-51c69319 className="logo" bis_skin_checked={1}>
                                                <img
                                                    data-v-51c69319
                                                    src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/ae.png?v=1.8.22"
                                                    className="aelive"
                                                />
                                            </div>
                                            <p data-v-51c69319>AE SEXY REALITY</p>
                                        </div>
                                    </div>
                                </div>
                                {/**/}
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => launchGame({ productid: "1052", gametype: "2" })}>
                            <div data-v-51c69319 data-v-1936baa7 className="navPlatItem navPlatItem-live" bis_skin_checked={1}>
                                <div data-v-51c69319 className="content content-live" bis_skin_checked={1}>
                                    <div data-v-51c69319 className="cover" bis_skin_checked={1}>
                                        <div data-v-51c69319 className="btn-play" bis_skin_checked={1}>
                                            <div data-v-51c69319 className="icon-play" bis_skin_checked={1} />
                                            <div data-v-51c69319 className="btn-txt" bis_skin_checked={1}>
                                                {" "}Bắt đầu chơi
                                            </div>
                                        </div>
                                    </div>
                                    <div data-v-51c69319 className="liveContainer" bis_skin_checked={1}>
                                        <div data-v-51c69319 className="top" bis_skin_checked={1}>
                                            <img data-v-51c69319 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/foreground/dg.png?v=1.8.22" alt="" className="foreground" />
                                            <img data-v-51c69319 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/croupier/dg.png?v=1.8.22" alt="" className="croupier" />
                                        </div>
                                        <div data-v-51c69319 className="bot" bis_skin_checked={1}>
                                            <div data-v-51c69319 className="logo" bis_skin_checked={1}>
                                                <img data-v-51c69319 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/dg.png?v=1.8.22" className="dreamgame" />
                                            </div>
                                            <p data-v-51c69319>DG CASINO</p>
                                        </div>
                                    </div>
                                </div>
                                {/**/}
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => launchGame({ productid: "1003", gametype: "2" })}>
                            <div data-v-51c69319 data-v-1936baa7 className="navPlatItem navPlatItem-live" bis_skin_checked={1}>
                                <div data-v-51c69319 className="content content-live" bis_skin_checked={1}>
                                    <div data-v-51c69319 className="cover" bis_skin_checked={1}>
                                        <div data-v-51c69319 className="btn-play" bis_skin_checked={1}>
                                            <div data-v-51c69319 className="icon-play" bis_skin_checked={1} />
                                            <div data-v-51c69319 className="btn-txt" bis_skin_checked={1}>
                                                {" "}Bắt đầu chơi
                                            </div>
                                        </div>
                                    </div>
                                    <div data-v-51c69319 className="liveContainer" bis_skin_checked={1}>
                                        <div data-v-51c69319 className="top" bis_skin_checked={1}>
                                            <img data-v-51c69319 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/foreground/db.png?v=1.8.22" alt="" className="foreground" />
                                            <img data-v-51c69319 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/croupier/db.png?v=1.8.22" alt="" className="croupier" />
                                        </div>
                                        <div data-v-51c69319 className="bot" bis_skin_checked={1}>
                                            <div data-v-51c69319 className="logo" bis_skin_checked={1}>
                                                <img data-v-51c69319 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/db.png?v=1.8.22" className="db" />
                                            </div>
                                            <p data-v-51c69319>DB CASINO</p>
                                        </div>
                                    </div>
                                </div>
                                {/**/}
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => launchGame({ productid: "1020", gametype: "2" })}>
                            <div data-v-51c69319 data-v-1936baa7 className="navPlatItem navPlatItem-live" bis_skin_checked={1}>
                                <div data-v-51c69319 className="content content-live" bis_skin_checked={1}>
                                    <div data-v-51c69319 className="cover" bis_skin_checked={1}>
                                        <div data-v-51c69319 className="btn-play" bis_skin_checked={1}>
                                            <div data-v-51c69319 className="icon-play" bis_skin_checked={1} />
                                            <div data-v-51c69319 className="btn-txt" bis_skin_checked={1}>
                                                {" "}Bắt đầu chơi
                                            </div>
                                        </div>
                                    </div>
                                    <div data-v-51c69319 className="liveContainer" bis_skin_checked={1}>
                                        <div data-v-51c69319 className="top" bis_skin_checked={1}>
                                            <img data-v-51c69319 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/foreground/wm.png?v=1.8.22" alt="" className="foreground" />
                                            <img data-v-51c69319 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/croupier/wm.png?v=1.8.22" alt="" className="croupier" />
                                        </div>
                                        <div data-v-51c69319 className="bot" bis_skin_checked={1}>
                                            <div data-v-51c69319 className="logo" bis_skin_checked={1}>
                                                <img data-v-51c69319 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/wm.png?v=1.8.22" className="wmlive" />
                                            </div>
                                            <p data-v-51c69319>WM CASINO</p>
                                        </div>
                                    </div>
                                </div>
                                {/**/}
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => launchGame({ productid: "1022", gametype: "2" })}>
                            <div data-v-51c69319 data-v-1936baa7 className="navPlatItem navPlatItem-live" bis_skin_checked={1}>
                                <div data-v-51c69319 className="content content-live" bis_skin_checked={1}>
                                    <div data-v-51c69319 className="cover" bis_skin_checked={1}>
                                        <div data-v-51c69319 className="btn-play" bis_skin_checked={1}>
                                            <div data-v-51c69319 className="icon-play" bis_skin_checked={1} />
                                            <div data-v-51c69319 className="btn-txt" bis_skin_checked={1}>
                                                {" "}Bắt đầu chơi
                                            </div>
                                        </div>
                                    </div>
                                    <div data-v-51c69319 className="liveContainer" bis_skin_checked={1}>
                                        <div data-v-51c69319 className="top" bis_skin_checked={1}>
                                            <img data-v-51c69319 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/foreground/ag.png?v=1.8.22" alt="" className="foreground" />
                                            <img data-v-51c69319 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/croupier/ag.png?v=1.8.22" alt="" className="croupier" />
                                        </div>
                                        <div data-v-51c69319 className="bot" bis_skin_checked={1}>
                                            <div data-v-51c69319 className="logo" bis_skin_checked={1}>
                                                <img data-v-51c69319 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/ag.png?v=1.8.22" className="aglive" />
                                            </div>
                                            <p data-v-51c69319>AG CASINO</p>
                                        </div>
                                    </div>
                                </div>
                                {/**/}
                            </div>
                        </SwiperSlide>
                        <SwiperSlide onClick={() => launchGame({ productid: "1030", gametype: "2" })}>
                            <div data-v-51c69319 data-v-1936baa7 className="navPlatItem navPlatItem-live" bis_skin_checked={1}>
                                <div data-v-51c69319 className="content content-live" bis_skin_checked={1}>
                                    <div data-v-51c69319 className="cover" bis_skin_checked={1}>
                                        <div data-v-51c69319 className="btn-play" bis_skin_checked={1}>
                                            <div data-v-51c69319 className="icon-play" bis_skin_checked={1} />
                                            <div data-v-51c69319 className="btn-txt" bis_skin_checked={1}>
                                                {" "}Bắt đầu chơi
                                            </div>
                                        </div>
                                    </div>
                                    <div data-v-51c69319 className="liveContainer" bis_skin_checked={1}>
                                        <div data-v-51c69319 className="top" bis_skin_checked={1}>
                                            <img data-v-51c69319 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/foreground/bbin.png?v=1.8.22" alt="" className="foreground" />
                                            <img data-v-51c69319 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/croupier/bbin.png?v=1.8.22" alt="" className="croupier" />
                                        </div>
                                        <div data-v-51c69319 className="bot" bis_skin_checked={1}>
                                            <div data-v-51c69319 className="logo" bis_skin_checked={1}>
                                                <img data-v-51c69319 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/bbin.png?v=1.8.22" className="bbinlive" />
                                            </div>
                                            <p data-v-51c69319>BBIN CASINO</p>
                                        </div>
                                    </div>
                                </div>
                                {/**/}
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div
                        data-v-1936baa7
                        slot="button-prev"
                        className="swiper-button swiper-button-prev realgame-swiper-button-prev swiper-button-disabled"
                        tabIndex={-1}
                        role="button"
                        aria-label="Previous slide"
                        aria-disabled="true"
                        bis_skin_checked={1}
                    />
                    <div
                        data-v-1936baa7
                        slot="button-next"
                        className="swiper-button swiper-button-next realgame-swiper-button-next"
                        tabIndex={0}
                        role="button"
                        aria-label="Next slide"
                        aria-disabled="false"
                        bis_skin_checked={1}
                    />
                </div>
            </div>
        </div>
    );
};

export default Megamenu;
