import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import "./game.css";
import { Link, useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";

function Casino() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    
    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    function launchGame(params) {
        if (!localStorage.getItem("profile")) {
            swal("Thông báo", "Vui lòng đăng nhập", "info");
            return;
        }
        if (loading === true) return;
        const form = {
            ...params,
            mobile: isMobileDevice() ? 1 : 0
        };
        console.log("Dữ liệu gửi đi trong launchGame:", form);
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/Seamless/LaunchGame`, form)
            .then((res) => {
                if (res.data.data.ErrorCode === 0) {
                    window.location.href = res.data.data.Url;
                } else {
                    swal("Sảnh đang bảo trì", "Vui lòng chọn sảnh khác", "error");
                }
                setLoading(false);
            })
            .catch((err) => {
                swal(
                    "Đã có lỗi xảy ra",
                    err.response.data.message !== "internal server error"
                        ? err.response.data.message
                        : "Vui lòng đăng xuất và đăng nhập lại",
                    "error"
                ).then(() => navigate("/login"));
                setLoading(false);
            });
    }

    return (
        <>
            {loading === true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <Box mt={3}>
                <div className="container">
                    <div className="banner-game" style={{ borderRadius: 10, overflow: "hidden" }}>
                        <img src={"https://yscduxhccunurys.com/home/static/img/live_banner.dc8a47f2.png"} alt="Banner" />
                    </div>
                    <Box mt={3}>
                        <Grid container spacing={3}>
                            {/* Ku lobby */}
                            <Grid item xs={6}>
                                <li
                                    data-v-2c89f64c
                                    data-v-22d591fa
                                    className="third-game-live actived fiveFelx live container-tran"
                                    providerlistobj="[object Object]"
                                    imgs="[object Object]"
                                    name="AE SEXY REALITY"
                                    rebate="0.0100"
                                    onClick={() => launchGame({ productid: "1022", gametype: "2" })}
                                >
                                    <div data-v-2c89f64c className="liveItem" bis_skin_checked={1}>
                                        <div data-v-2c89f64c className="imgs" bis_skin_checked={1}>
                                            <img
                                                data-v-2c89f64c
                                                src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/croupier/ae.png?v=1.8.22"
                                                alt=""
                                                className="croupier tran-scale-105"
                                            />
                                            <img
                                                data-v-2c89f64c
                                                src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/foreground/ae.png?v=1.8.22"
                                                alt=""
                                                className="foreground"
                                            />
                                        </div>
                                        <div data-v-2c89f64c className="playInfo" bis_skin_checked={1}>
                                            <img
                                                data-v-2c89f64c
                                                src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/ae.png?v=1.8.22"
                                                alt=""
                                                className="platLogo"
                                            />
                                            <p data-v-2c89f64c className="playCode">SEXY BACCARAT</p>
                                            <div data-v-2c89f64c className="playRebateWp" bis_skin_checked={1}>
                                                <span data-v-2c89f64c className="playRebate">Phí hoàn tối đa 2.8%</span>
                                            </div>
                                            <div data-v-2c89f64c className="playButton tran-scale-105" bis_skin_checked={1}>
                                                Vào trò chơi
                                            </div>
                                        </div>
                                        <div data-v-2c89f64c className="ani-container" style={{ textAlign: "left", display: "none" }} bis_skin_checked={1}>
                                            <canvas width={688} height={200} style={{ backgroundColor: "transparent" }} />
                                        </div>
                                    </div>
                                </li>
                            </Grid>

                            {/* DG CASINO */}
                            <Grid item xs={6}>
                                <li
                                    data-v-2c89f64c
                                    data-v-22d591fa
                                    className="third-game-live fiveFelx live container-tran"
                                    providerlistobj="[object Object]"
                                    imgs="[object Object]"
                                    name="DG CASINO"
                                    rebate="0.0100"
                                    onClick={() => launchGame({ productid: "1052", gametype: "2" })}
                                >
                                    <div data-v-2c89f64c className="liveItem" bis_skin_checked={1}>
                                        <div data-v-2c89f64c className="imgs" bis_skin_checked={1}>
                                            <img
                                                data-v-2c89f64c
                                                src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/croupier/dg.png?v=1.8.22"
                                                alt=""
                                                className="croupier  tran-scale-105"
                                            />
                                            <img
                                                data-v-2c89f64c
                                                src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/foreground/dg.png?v=1.8.22"
                                                alt=""
                                                className="foreground"
                                            />
                                        </div>
                                        <div data-v-2c89f64c className="playInfo" bis_skin_checked={1}>
                                            <img
                                                data-v-2c89f64c
                                                src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/dg.png?v=1.8.22"
                                                alt=""
                                                className="platLogo"
                                            />
                                            <p data-v-2c89f64c className="playCode">
                                                DG CASINO
                                            </p>
                                            <div data-v-2c89f64c className="playRebateWp" bis_skin_checked={1}>
                                                <span data-v-2c89f64c className="playRebate">
                                                    Phí hoàn tối đa 1%
                                                </span>
                                            </div>
                                            <div data-v-2c89f64c className="playButton tran-scale-105" bis_skin_checked={1}>
                                                Vào trò chơi
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </Grid>
                            {/* DB CASINO */}
                            <Grid item xs={6}>
                                <li
                                    data-v-2c89f64c
                                    data-v-22d591fa
                                    className="third-game-live fiveFelx live container-tran"
                                    providerlistobj="[object Object]"
                                    imgs="[object Object]"
                                    name="DB CASINO"
                                    rebate="0.0100"
                                    onClick={() => launchGame({ productid: "1003", gametype: "2" })}
                                >
                                    <div data-v-2c89f64c className="liveItem" bis_skin_checked={1}>
                                        <div data-v-2c89f64c className="imgs" bis_skin_checked={1}>
                                            <img
                                                data-v-2c89f64c
                                                src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/croupier/db.png?v=1.8.22"
                                                alt=""
                                                className="croupier tran-scale-105"
                                            />
                                            <img
                                                data-v-2c89f64c
                                                src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/foreground/db.png?v=1.8.22"
                                                alt=""
                                                className="foreground"
                                            />
                                        </div>
                                        <div data-v-2c89f64c className="playInfo" bis_skin_checked={1}>
                                            <img
                                                data-v-2c89f64c
                                                src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/db.png?v=1.8.22"
                                                alt=""
                                                className="platLogo"
                                            />
                                            <p data-v-2c89f64c className="playCode">
                                                DB CASINO
                                            </p>
                                            <div data-v-2c89f64c className="playRebateWp" bis_skin_checked={1}>
                                                <span data-v-2c89f64c className="playRebate">
                                                    Phí hoàn tối đa 1%
                                                </span>
                                            </div>
                                            <div data-v-2c89f64c className="playButton tran-scale-105" bis_skin_checked={1}>
                                                Vào trò chơi
                                            </div>
                                        </div>
                                        <div data-v-2c89f64c className="ani-container" bis_skin_checked={1} style={{ textAlign: "left", display: "none" }}>
                                            <canvas width={688} height={200} style={{ backgroundColor: "transparent" }} />
                                        </div>
                                    </div>
                                </li>
                            </Grid>
                            {/* WM CASINO */}
                            <Grid item xs={6}>
                                <li
                                    data-v-2c89f64c
                                    data-v-22d591fa
                                    className="third-game-live fiveFelx live container-tran"
                                    providerlistobj="[object Object]"
                                    imgs="[object Object]"
                                    name="WM CASINO"
                                    rebate="0.0100"
                                    onClick={() => launchGame({ productid: "1020", gametype: "2" })}
                                >
                                    <div data-v-2c89f64c className="liveItem" bis_skin_checked={1}>
                                        <div data-v-2c89f64c className="imgs" bis_skin_checked={1}>
                                            <img
                                                data-v-2c89f64c
                                                src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/croupier/wm.png?v=1.8.22"
                                                alt=""
                                                className="croupier tran-scale-105"
                                            />
                                            <img
                                                data-v-2c89f64c
                                                src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/foreground/wm.png?v=1.8.22"
                                                alt=""
                                                className="foreground"
                                            />
                                        </div>
                                        <div data-v-2c89f64c className="playInfo" bis_skin_checked={1}>
                                            <img
                                                data-v-2c89f64c
                                                src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/wm.png?v=1.8.22"
                                                alt=""
                                                className="platLogo"
                                            />
                                            <p data-v-2c89f64c className="playCode">
                                                WM CASINO
                                            </p>
                                            <div data-v-2c89f64c className="playRebateWp" bis_skin_checked={1}>
                                                <span data-v-2c89f64c className="playRebate">
                                                    Phí hoàn tối đa 1%
                                                </span>
                                            </div>
                                            <div data-v-2c89f64c className="playButton tran-scale-105" bis_skin_checked={1}>
                                                Vào trò chơi
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </Grid>
                            {/* AG CASINO */}
                            <Grid item xs={6}>
                                <li
                                    data-v-2c89f64c
                                    data-v-22d591fa
                                    className="third-game-live fiveFelx live container-tran"
                                    providerlistobj="[object Object]"
                                    imgs="[object Object]"
                                    name="AG CASINO"
                                    rebate="0.0100"
                                    onClick={() => launchGame({ productid: "1022", gametype: "2" })}
                                >
                                    <div data-v-2c89f64c className="liveItem" bis_skin_checked={1}>
                                        <div data-v-2c89f64c className="imgs" bis_skin_checked={1}>
                                            <img
                                                data-v-2c89f64c
                                                src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/croupier/ag.png?v=1.8.22"
                                                alt=""
                                                className="croupier tran-scale-105"
                                            />
                                            <img
                                                data-v-2c89f64c
                                                src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/foreground/ag.png?v=1.8.22"
                                                alt=""
                                                className="foreground"
                                            />
                                        </div>
                                        <div data-v-2c89f64c className="playInfo" bis_skin_checked={1}>
                                            <img
                                                data-v-2c89f64c
                                                src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/ag.png?v=1.8.22"
                                                alt=""
                                                className="platLogo"
                                            />
                                            <p data-v-2c89f64c className="playCode">
                                                AG CASINO
                                            </p>
                                            <div data-v-2c89f64c className="playRebateWp" bis_skin_checked={1}>
                                                <span data-v-2c89f64c className="playRebate">
                                                    Phí hoàn tối đa 1%
                                                </span>
                                            </div>
                                            <div data-v-2c89f64c className="playButton tran-scale-105" bis_skin_checked={1}>
                                                Vào trò chơi
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </Grid>
                            {/* BBIN CASINO */}
                            <Grid item xs={6}>
                                <li
                                    data-v-2c89f64c
                                    data-v-22d591fa
                                    className="third-game-live fiveFelx live container-tran"
                                    providerlistobj="[object Object]"
                                    imgs="[object Object]"
                                    name="BBIN CASINO"
                                    rebate="0.0100"
                                    onClick={() => launchGame({ productid: "1022", gametype: "2" })}
                                >
                                    <div data-v-2c89f64c className="liveItem" bis_skin_checked={1}>
                                        <div data-v-2c89f64c className="imgs" bis_skin_checked={1}>
                                            <img
                                                data-v-2c89f64c
                                                src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/croupier/bbin.png?v=1.8.22"
                                                alt=""
                                                className="croupier tran-scale-105"
                                            />
                                            <img
                                                data-v-2c89f64c
                                                src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/live/foreground/bbin.png?v=1.8.22"
                                                alt=""
                                                className="foreground"
                                            />
                                        </div>
                                        <div data-v-2c89f64c className="playInfo" bis_skin_checked={1}>
                                            <img
                                                data-v-2c89f64c
                                                src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/bbin.png?v=1.8.22"
                                                alt=""
                                                className="platLogo"
                                            />
                                            <p data-v-2c89f64c className="playCode">
                                                BBIN CASINO
                                            </p>
                                            <div data-v-2c89f64c className="playRebateWp" bis_skin_checked={1}>
                                                <span data-v-2c89f64c className="playRebate">
                                                    Phí hoàn tối đa 1%
                                                </span>
                                            </div>
                                            <div data-v-2c89f64c className="playButton tran-scale-105" bis_skin_checked={1}>
                                                Vào trò chơi
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </Box>
        </>
    );
}

export default Casino;
