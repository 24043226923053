import React from "react";
import "./account.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import swal from "sweetalert";
import EditPassword from "./EditPassword";
import EditPayPassword from "./EditPayPassword";

function Account({ profile, menuClick }) {
    const { register, handleSubmit } = useForm();
    const [balance, setBalance] = useState(0);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const logOut = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("currentUser");
        localStorage.removeItem("profile");
        localStorage.removeItem("data");
        localStorage.removeItem("data1");
        var now = new Date();
        var time = now.getTime();
        var expireTime = time;
        now.setTime(expireTime);
        document.cookie = "jwt=;Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        window.location.reload();
    };
    const [activeOption, setActiveOption] = useState(1);
    // const handleOptionClick = (option) => {
    //     setActiveOption(option);
    // };
    // const onSubmitPass = (data) => {
    //     if (!data.oldpassword) {
    //         swal("Thông báo", "Vui lòng nhập mật khẩu cũ", "warning");
    //         return;
    //     }
    //     if (data.oldpassword.length < 6 || data.password.length < 6 || data.checkpassword.length < 6) {
    //         swal("Thông báo", "Mật khẩu tối thiểu 6 kí tự", "warning");
    //         return;
    //     }
    //     if (data.password != data.checkpassword) {
    //         swal("Thông báo", "Xác nhận mật khẩu mới không đúng", "error");
    //         return;
    //     }
    //     const formData = {
    //         password: data.oldpassword,
    //         newpassword: data.password
    //     };
    //     axios
    //         .post(`${process.env.REACT_APP_API_URL}/auth/password`, formData)
    //         .then((res) => {
    //             swal({
    //                 title: "Đổi mật khẩu thành công",
    //                 text: "Vui lòng đăng nhập lại",
    //                 icon: "success",
    //                 buttons: "OK"
    //             }).then(() => logOut());
    //         })
    //         .catch((err) => swal("Thông báo", "Mật khẩu cũ không chính xác", "error"));
    // };
    const onSubmitPass2 = (data) => {
        if (!data.oldpassword) {
            swal("Thông báo", "Vui lòng nhập mật khẩu cũ", "warning");
            return;
        }
        if (data.oldpassword.length < 6 || data.password.length < 6 || data.checkpassword.length < 6) {
            swal("Thông báo", "Mật khẩu tối thiểu 6 kí tự", "warning");
            return;
        }
        if (data.password != data.checkpassword) {
            swal("Thông báo", "Xác nhận mật khẩu mới không đúng", "error");
            return;
        }
        const formData = {
            password: data.oldpassword,
            newpassword: data.password
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/changepass2`, formData)
            .then((res) => {
                swal({
                    title: "Đổi mật khẩu rút tiền thành công",
                    text: "Vui lòng đăng nhập lại",
                    icon: "success",
                    buttons: "OK"
                }).then(() => logOut());
            })
            .catch((err) => swal("Thông báo", "Mật khẩu cũ không chính xác", "error"));
    };
    /*useEffect(() => {
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/getbalance`, {})
            .then((res) => {
                setBalance(res.data.data.balance);
            })
            .catch((err) => setBalance(0));
    }, [profile]);*/
    return (
        <>
            <div className="profile">
                <div data-v-429ecedc data-v-591b87cf className="information" selecttypeindex="vnd" bis_skin_checked={1}>
                    <div data-v-429ecedc className="cardWrap" bis_skin_checked={1}>
                        <div data-v-2533025a data-v-429ecedc className="cardInfo cardBtnHover" bis_skin_checked={1}>
                            <div data-v-2533025a className="cardTitle" bis_skin_checked={1}>
                                <span data-v-2533025a className="title">
                                    Thông tin cá nhân
                                </span>
                            </div>
                            <div data-v-2533025a className="cardBody" bis_skin_checked={1}>
                                <p data-v-2533025a className>
                                    <span data-v-2533025a />
                                </p>
                                <form data-v-429ecedc className="el-form" data-v-2533025a>
                                    <div data-v-429ecedc className="el-form-item el-form-item--mini" bis_skin_checked={1}>
                                        <label className="el-form-item__label" style={{ width: "170px" }}>
                                            Tài khoản
                                        </label>
                                        <div className="el-form-item__content" style={{ marginLeft: "170px" }} bis_skin_checked={1}>
                                            <p data-v-429ecedc className="lh40 usernames">
                                                {profile?.name}
                                            </p>
                                        </div>
                                    </div>
                                    <div data-v-429ecedc className="el-form-item el-form-item--mini" bis_skin_checked={1}>
                                        <label className="el-form-item__label" style={{ width: "170px" }}>
                                            Họ tên
                                        </label>
                                        <div className="el-form-item__content" style={{ marginLeft: "170px" }} bis_skin_checked={1}>
                                            <p data-v-429ecedc className="lh40">
                                                {" "}
                                                {profile?.username}
                                            </p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div data-v-2533025a data-v-429ecedc className="cardInfo cardBtnHover" bis_skin_checked={1}>
                            <div data-v-2533025a className="cardTitle" bis_skin_checked={1}>
                                <span data-v-2533025a className="title">
                                    Bảo mật tài khoản
                                </span>
                            </div>
                            <div data-v-2533025a className="cardBody" bis_skin_checked={1}>
                                <p data-v-2533025a className>
                                    <span data-v-2533025a />
                                </p>
                                <form data-v-429ecedc className="el-form" data-v-2533025a>
                                    {/* <div data-v-429ecedc className="el-form-item el-form-item--mini" bis_skin_checked={1}>
                                        <label className="el-form-item__label" style={{ width: "170px" }}>
                                            Liên kết Email
                                        </label>
                                        <div className="el-form-item__content" style={{ marginLeft: "170px" }} bis_skin_checked={1}>
                                            <p data-v-429ecedc className="lh40">
                                                da****@gmail.com
                                            </p>
                                        </div>
                                    </div> */}
                                    <div data-v-429ecedc className="el-form-item el-form-item--mini" bis_skin_checked={1}>
                                        <label className="el-form-item__label" style={{ width: "170px" }}>
                                            Số điện thoại
                                        </label>
                                        <div className="el-form-item__content" style={{ marginLeft: "170px" }} bis_skin_checked={1}>
                                            <p data-v-429ecedc className="lh40">
                                                {" "}
                                                0{profile?.sdt}
                                            </p>
                                        </div>
                                    </div>
                                    <div data-v-429ecedc className="el-form-item el-form-item--mini" bis_skin_checked={1}>
                                        <label className="el-form-item__label" style={{ width: "170px" }}>
                                            Mật khẩu đăng nhập
                                        </label>
                                        <div className="el-form-item__content" style={{ marginLeft: "170px" }} bis_skin_checked={1}>
                                            <p data-v-429ecedc className="lh40">
                                                ******
                                            </p>
                                            <EditPassword />
                                            {/* <button data-v-429ecedc type="button" className="el-button ml24 w124 el-button--primary el-button--mini">
                                                <span>Thay đổi</span>
                                            </button> */}
                                        </div>
                                    </div>
                                    <div data-v-429ecedc className="el-form-item el-form-item--mini" bis_skin_checked={1}>
                                        <label className="el-form-item__label" style={{ width: "170px" }}>
                                            Mật khẩu rút tiền
                                        </label>
                                        <div className="el-form-item__content" style={{ marginLeft: "170px" }} bis_skin_checked={1}>
                                            <p data-v-429ecedc className="lh40">
                                                ******
                                            </p>
                                            <EditPayPassword />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {console.log("profile", profile)} */}
                {profile && (
                    <>
                        {/* {activeOption == 1 && (
                            <div className="information-wrap personal-information">
                                <div className="information-top">
                                    <div className="upper-right">
                                        <div className="info-left">
                                            <div className="nickname-wrap">
                                                <label data-key="nickname" className="ellipsis information-nickname">
                                                    {profile?.username}
                                                </label>
                                                <div className="edit-icon"></div>
                                            </div>
                                            <div className="copy-wrap">
                                                <div className="copy-title" translate="user_name">
                                                    Số điện thoại
                                                </div>
                                                <div className="copy-btn">
                                                    <label className="username-copy">0{profile?.sdt}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="first-name active">
                                            <img src={require("../../../image/0.png")} />
                                        </div>
                                    </div>
                                </div>
                                <div className="information-money auto">
                                    <div>
                                        <div className="tips-text" translate="label_account_totalMoney">
                                            Số dư ví chính
                                        </div>
                                        <div className="amount-wrap">
                                            <div className="money-view js_sumBalance" style={{ color: "#ccac57" }}>
                                                <span>₫</span> {Math.floor(profile?.money).toLocaleString("vi-VN")}
                                            </div>
                                            <div className="refresh" id="refresh"></div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="tips-text" translate="label_account_totalMoney">
                                            Mã giới thiệu
                                        </div>
                                        <div className="amount-wrap">
                                            <div className="money-view js_sumBalance">{profile?.code}</div>
                                            <div className="refresh" id="refresh"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="money-plan" id="money-plan">
                                    <div className="plan-item">
                                        <div className="plan-wrap ellipsis">
                                            <label>Số tiền đã nạp</label>
                                            <div className="now js_now">{(profile?.tongnap).toLocaleString("vi-VN")}</div>
                                        </div>
                                    </div>
                                    <div className="plan-item">
                                        <div className="plan-wrap ellipsis">
                                            <label>Số tiền khuyến mãi</label>
                                            <div className="now js_now">{(profile?.khuyenmai).toLocaleString("vi-VN")}</div>
                                        </div>
                                    </div>
                                    <div className="plan-item">
                                        <div className="plan-wrap ellipsis">
                                            <label>Số tiền đã cược</label>
                                            <div className="now js_now">{(profile?.totalbet + profile?.totalbetapi).toLocaleString("vi-VN")}</div>
                                        </div>
                                    </div>
                                    <div className="plan-item">
                                        <div className="plan-wrap ellipsis">
                                            <label>Cược chưa thanh toán</label>
                                            <div className="now js_now">{(profile?.processing).toLocaleString("vi-VN")}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )} */}
                        {activeOption == 2 && (
                            {/* <div className="password withdraw">
                                <h2>Đổi mật khẩu đăng nhập</h2>
                                <form onSubmit={handleSubmit(onSubmitPass)}>
                                    <div>
                                        <div className="input">
                                            <input className="ipadd" type="password" {...register("oldpassword")} placeholder="Mật khẩu cũ" />
                                        </div>
                                        <div className="input">
                                            <input className="ipadd" type="password" {...register("password")} placeholder="Mật khẩu mới" />
                                        </div>
                                        <div className="input">
                                            <input className="ipadd" type="password" {...register("checkpassword")} placeholder="Nhập lại mật khẩu" />
                                        </div>
                                        <button type="submit" className="btn-red">
                                            Xác nhận
                                        </button>
                                    </div>
                                </form>
                            </div> */}
                        )}
                        {activeOption == 3 && (
                            <div className="password withdraw">
                                <h2>Đổi mật khẩu rút tiền</h2>
                                <form onSubmit={handleSubmit(onSubmitPass2)}>
                                    <div>
                                        <div className="input">
                                            <input className="ipadd" type="password" {...register("oldpassword")} placeholder="Mật khẩu rút tiền cũ" />
                                        </div>
                                        <div className="input">
                                            <input className="ipadd" type="password" {...register("password")} placeholder="Mật khẩu rút tiền mới" />
                                        </div>
                                        <div className="input">
                                            <input className="ipadd" type="password" {...register("checkpassword")} placeholder="Nhập lại mật khẩu mới" />
                                        </div>
                                        <button type="submit" className="btn-red">
                                            Xác nhận
                                        </button>
                                    </div>
                                </form>
                            </div>
                        )}
                        {/* <div className="information-wrap account-information js_platformList ps">
                            <div className="account-bind-item js_profile" onClick={() => handleOptionClick(1)}>
                                <div className="iconinfo icon-info active"></div>
                                <div className="item-text">
                                    <label translate="label_setting_data">Thông tin của tôi</label>
                                    <div className="icons-danger"></div>
                                </div>
                                <div className="item-detail" translate="info_setting_profile">
                                    Trang cá nhân của bạn
                                </div>
                            </div>
                            <div className="account-bind-item js_loginPassword" onClick={() => handleOptionClick(2)}>
                                <div className="iconinfo icon-pwd active"></div>
                                <div className="item-text">
                                    <label translate="label_pwd_login">Mật khẩu đăng nhập</label>
                                    <div className="icons-checked-green"></div>
                                </div>
                                <div className="item-detail" translate="info_setting_loginPwd">
                                    Khuyến nghị kết hợp chữ cái và số để tăng độ bảo mật
                                </div>
                            </div>
                            <div className="account-bind-item js_bankCards" onClick={menuClick}>
                                <div className="iconinfo icon-bank active"></div>
                                <div className="item-text">
                                    <label translate="label_bank_bind">Thêm thẻ ngân hàng</label>
                                    <div className="icons-danger"></div>
                                </div>
                                <div className="item-detail" translate="info_setting_bank">
                                    Sử dụng thẻ ngân hàng chính chủ để thanh toán nạp và rút tiền. Vui lòng điền đúng thông tin để tránh xảy ra nhầm lẫn trong quá trình giao dịch
                                </div>
                            </div>
                            <div className="account-bind-item js_withdrawPassword" onClick={() => handleOptionClick(3)}>
                                <div className="iconinfo icon-moneypwd active"></div>
                                <div className="item-text">
                                    <label translate="label_pwd_pay">Mật khẩu rút tiền</label>
                                    <div className="icons-danger"></div>
                                </div>
                                <div className="item-detail" translate="info_setting_payPwd">
                                    Cài đặt mật khẩu khi rút tiền sẽ nâng cao tính bảo mật và an toàn hơn cho tài khoản của quý khách
                                </div>
                            </div>
                            <div className="account-bind-item js-logout" onClick={logOut}>
                                <div className="iconinfo icon-logout active"></div>
                                <div className="item-text" translate="label_logout">
                                    Thoát
                                </div>
                                <div className="item-detail" translate="info_setting_logout">
                                    Đăng xuất an toàn
                                </div>
                            </div>
                        </div> */}
                    </>
                )}
            </div>
        </>
    );
}
export default Account;
