import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./history.css";

function HistoryWithdraw() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [payment, setPayment] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/payment/paymentus`, {})
            .then((res) => {
                setPayment(res.data.data);
            })
            .catch((err) => console.log(err));
    }, []);
    return (
        <>
            <div className="historywithdraw">
                <div data-v-2533025a data-v-54a0cefc className="cardInfo noMargin" bis_skin_checked={1}>
                    <div data-v-2533025a className="cardTitle" bis_skin_checked={1}>
                        <span data-v-2533025a className="title">
                            Lịch sử rút tiền
                        </span>
                    </div>
                </div>
                <div data-v-2533025a className="cardBody paddingTop0 padding20" bis_skin_checked={1}>
                    {payment ? (
                        <div className="main-history">
                            <div className="header-history">
                                <div style={{ width: "15%" }}>Loại giao dịch</div>
                                <div style={{ width: "15%" }}>Thời gian giao dịch</div>
                                <div style={{ width: "15%" }}>Số tiền giao dịch</div>
                                <div style={{ width: "15%" }}>Mã giao dịch</div>
                                <div style={{ width: "15%" }}>Trạng thái</div>
                                <div style={{ width: "25%" }}>Nội dung giao dịch</div>
                            </div>
                            {payment?.map((item, key) => (
                                <>
                                    {item?.type_payment === "RÚT" && (
                                        <>
                                            <div className="content-history">
                                                <div style={{ width: "15%" }}>{item?.type_payment}</div>
                                                <div style={{ width: "15%" }}>{formatDate(new Date(item?.createdAt))}</div>
                                                <div style={{ width: "15%", color: "red" }}>{Math.floor(item?.money).toLocaleString("vi-VN")}</div>
                                                <div style={{ width: "15%" }}>{item?._id.substring(0, 10)}</div>
                                                <div style={{ width: "15%" }}>
                                                    <span
                                                        className={`text_status ${
                                                            item?.status_payment === "Pending" ? "pending" : item?.status_payment === "Success" ? "win" : "lose"
                                                        }`}>
                                                        {item?.status_payment === "Pending" ? "Chờ xử lý" : item?.status_payment === "Success" ? "Thành công" : "Thất bại"}
                                                    </span>
                                                </div>
                                                <div style={{ width: "25%", textAlign: "left", paddingLeft: "40px" }}>
                                                    Phương thức : {item?.detail}
                                                    <br />
                                                    Khuyến mãi: {item?.km ? Number(Math.floor(item?.km)).toLocaleString("vi-VN") : 0}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            ))}
                            {payment?.filter((item) => item?.type_payment === "RÚT")?.length <= 0 && (
                                <div className="el-table__empty-block" style={{ height: "100%", width: "100%" }} bis_skin_checked={1}>
                                    <span className="el-table__empty-text">
                                        <p data-v-2b2c2fe2 className="noData">
                                            Hiện không có chi tiết giao dịch
                                        </p>
                                    </span>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div></div>
                    )}
                </div>
            </div>
        </>
    );
}
export default HistoryWithdraw;
