import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import "./recharge.css";

function Recharge({ profile, getProfile }) {
    const [bank, setBank] = useState(null);
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [payment, setPayment] = useState([]);
    const [newMoney, setNewMoney] = useState(null);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab]= useState(1)
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getbank`, {})
            .then((res) => {
                setBank(res.data.data);
            })
            .catch((err) => setBank(null));
        axios
            .get(`${process.env.REACT_APP_API_URL}/setting/get`, {})
            .then((res) => {
                if (setting != res.data.data[0]) {
                    setSetting(res.data.data[0]);
                    localStorage.setItem("setting", JSON.stringify(res.data.data[0]));
                }
            })
            .catch((err) => setSetting(null));
        axios
            .get(`${process.env.REACT_APP_API_URL}/payment/paymentus`, {})
            .then((res) => {
                if (res.data.data) setPayment(res.data.data);
            })
            .catch((err) => setPayment(null));
    }, []);
    const [activeOption, setActiveOption] = useState(0);
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    const [activeOption2, setActiveOption2] = useState("Techcombank");
    const handleOptionClick2 = (option) => {
        setActiveOption2(option);
    };
    const onSubmit = (data) => {
        const formData = {
            money: Number(data.money.replaceAll(".", "").replaceAll(",", "")),
            type_payment: "NẠP",
            detail: data.detail,
            status_payment: "Pending",
            user: profile._id
        };
        if (Number(data.money.replaceAll(".", "").replaceAll(",", "")) <= 0 || typeof Number(data.money.replaceAll(".", "").replaceAll(",", "")) !== "number") {
            swal("Thông báo", "Vui lòng nhập số tiền hợp lệ", "error");
            return false;
        }
        if (Number(data.money.replaceAll(".", "").replaceAll(",", "")) < setting?.minnap) {
            swal("Chú ý", `Số tiền nạp tối thiểu là ${(setting?.minnap).toLocaleString("vi-VN")}`, "warning");
            return false;
        }
        if (Number(data.money.replaceAll(".", "").replaceAll(",", "")) > setting?.maxnap) {
            swal("Chú ý", `Số tiền nạp tối đa là ${(setting?.maxnap).toLocaleString("vi-VN")}`, "warning");
            return false;
        }
        if (activeOption != 0) {
            if (payment) {
                const count = payment.filter((obj) => obj.status_payment === "Pending" && obj.type_payment === "NẠP");
                if (count.length > 1) {
                    swal("Thông báo", `Bạn đang có ${count.length} lệnh nạp tiền chưa xử lý, không thể tạo thêm lệnh nạp tiền.`, "error");
                    return false;
                }
            }
            setLoading(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/payment/withDraw`, formData)
                .then((res) => {
                    swal("Nạp tiền thành công", "Tiền sẽ được cộng trong vòng 5 phút. Nếu quá lâu vui lòng liên hệ CSKH để được xử lý.", "success");
                    getProfile();
                    setLoading(false);
                })
                .catch((err) => {
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                    setLoading(false);
                });
        } else {
            setLoading(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/bank/createTrans`, {
                    amount: Number(data.money.replaceAll(".", "").replaceAll(",", "")),
                    type: activeOption2
                })
                .then((res) => {
                    window.location.href = res.data.data.url;
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Ngân hàng bảo trì, vui lòng chọn ngân hàng khác", "error");
                });
        }
    };
    return (
        <>
            {loading === true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            
                <div data-v-2533025a className="recharge">
                    <div data-v-2533025a data-v-54a0cefc className="cardInfo noMargin" bis_skin_checked={1}>
                        <div data-v-2533025a className="cardTitle" bis_skin_checked={1}>
                            <span data-v-2533025a className="title">
                                Nạp tiền
                            </span>
                        </div>
                        <div data-v-2533025a className="cardBody paddingTop0 padding20" bis_skin_checked={1}>
                            <p data-v-2533025a className>
                                <span data-v-2533025a />
                            </p>
                            <div data-v-54a0cefc data-v-2533025a className="titleTab" bis_skin_checked={1}>
                                <div onClick={()=> {setActiveTab(1)}} data-v-54a0cefc data-v-2533025a className={activeTab== 1 ? "active" : ""} bis_skin_checked={1}>
                                    {" "}
                                    Chuyển khoản qua thẻ ngân hàng{" "}
                                </div>
                                <div onClick={()=> {setActiveTab(2)}} data-v-54a0cefc data-v-2533025a  className={activeTab== 2 ? "active" : ""} bis_skin_checked={1}>
                                    {" "}
                                    Ngân hàng nội địa{" "}
                                </div>
                                <div onClick={()=> {setActiveTab(3)}} data-v-54a0cefc data-v-2533025a  className={activeTab== 3 ? "active" : ""} bis_skin_checked={1}>
                                    {" "}
                                    Ví Momo{" "}
                                </div>
                            </div>
                            {activeTab=== 1 && 
                                <div>
                                    <form data-v-54a0cefc className="el-form" data-v-2533025a>
                                        <div data-v-54a0cefc className="el-form-item" bis_skin_checked={1}>
                                            <label className="el-form-item__label">
                                                <span data-v-54a0cefc className="label dispalyNone">
                                                    Phương thức:
                                                </span>
                                            </label>
                                            <div className="el-form-item__content" bis_skin_checked={1}>
                                                <div data-v-54a0cefc className="tags" bis_skin_checked={1}>
                                                    <div data-v-54a0cefc className="tagsDiv" bis_skin_checked={1}>
                                                        <span data-v-54a0cefc type="info" className="active">
                                                            {" "}
                                                            Ngân hàng trực tuyến{" "}
                                                            <img
                                                                data-v-54a0cefc
                                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEIAAABRCAMAAABRyIDvAAAAz1BMVEUAAAD/MzP/MzP/NTX/PT3/MzP/NDT/MzP/MzP/MzP/MzP/MzP/NTX/Q0P/MzP/MzP/MzP/MzP/MzP/MzP/NDT/NDT/NDT/Nzf/MzP/NDT/NDT/Njb/MzP/MzP/MzP/NDT/NDT/NDT/MzP/NDT/NTX/Nzf/NTX/NDT/MzP/////U1P/np7/i4v/9PT/y8v/3d3/+fn/6Oj/8PD/h4f/ODj/l5f/0tL/gYH/wcH/pKT/eHj/SEj/xsb/XFz/QUH/4+P/2dn/aWn/urr/rKz/kJCJOjwUAAAAKHRSTlMA+N4cDJBE/OZ7yacpB/Dr2JqIu4FjWxSuazw3087ConRKs28yJCFQvylXDgAAAxdJREFUWMOk1Nl2gjAUBdATgkxKC4hSx9Zxnf//wmpgRRuUkmQ/5ekm954kGFBVEp5qijSHlxXJIvIuQVa5dwnOQ59ZtLYSrr7ZCZxr7KlrNHATUMtCuAhjPkzh4sBnKziY8Jk4w17BP7Y5bEkaUtg60SCuUBr7PsxU5ATjrNm3gZLVGGXKvjmUikuM0Ai+0Aa7pJjhfxW13jQ2ZBE6HoKxxM2MZGl5Mx9SdCUYYZgUfC3QYQUhehY5tJLvrNU47yYvUlzo5YZvlXqD+AJTVujljm8lABIqRxhyoSd04oAzFuyYE410d/KDA9ImYSfrxxhAOXLILqMW9f/aq0qGo+3NPsgDgIvgeGs8+eLNCUDGkcxQZNzdlpo2Ymm+iSki2knNhzmXn7QTAJ1aB2ZJ5xp+0FWJ1g+dJVDyhO5muFvRkc4kD+hh3/7pPsRv6WW6nCAMBODFo62VSlvr1NZ2nB7qBrVGRUS8j/d/pm6IMxmdEBS+P64Z+EiyhGxkPZSJFoCNiXhooHO66ex9fyyjwPdDGU0XI8bXszmFh4HigEdeTnfwP8a6Mhoy1kNivmESPkWcMcUMj9QhjybFasiINWdEiN0+QZH46aqU1IyKgFHLCr2wTzeukHCpxT0poODJpFjS9RMUbIVLq8hB3aSYyWcLaE42WoUD92eKxThiFCl8+o+SLt3q6RQW3J0pFL2oL3uUjKllrlNUwDIpRip50zjFIzhnCn8SwSPFgLEgaSBV+NBPp5yLHWNDDyMWjA1Qp2hC1aQQvZ+iYBmb1CKUTQqP5pOHwiCCuVZRg5JJgVsu3u9gwGV3dAobfo0KDLlKkFbhiJrFqEB3JxYWD5YocWmJuWe7QBMT8Nyt66EeWQ10MAt3D/LUkIESZP2CW6+qRkrJC0DGbjgFVSWlRB1tCimHUgbF+y2mQB0G0lYYFRsgm+P2BiCbw+oAZHNYX6Dhy7liaTRAS76KF/KZhzhKlyW3+ADxtJvJktw7mLG/0Yj1XIBEGk+GOrTehsuw38o5zct0X2rBNRQatedm9cOpEM5nvfhzEzOCfwSaH4K/tD/iAAAAAElFTkSuQmCC"
                                                                alt=""
                                                                className="isHot"
                                                                style={{}}
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div data-v-54a0cefc className="bankListDiv" bis_skin_checked={1}>
                                                    {activeOption == 0 && (
                                                        <div className="content_bank">
                                                            <ul className="tab-navigation tab-game tab-bank">
                                                                <li className={activeOption2 === "Techcombank" ? "active" : ""} onClick={() => handleOptionClick2("Techcombank")}>
                                                                    <img src="https://api.vietqr.io/img/TCB.png" />
                                                                </li>
                                                                <li className={activeOption2 === "Vietcombank" ? "active" : ""} onClick={() => handleOptionClick2("Vietcombank")}>
                                                                    <img src="https://api.vietqr.io/img/VCB.png" />
                                                                </li>
                                                                <li className={activeOption2 === "Eximbank" ? "active" : ""} onClick={() => handleOptionClick2("Eximbank")}>
                                                                    <img src="https://api.vietqr.io/img/EIB.png" />
                                                                </li>
                                                                <li className={activeOption2 === "BIDV" ? "active" : ""} onClick={() => handleOptionClick2("BIDV")}>
                                                                    <img src="https://api.vietqr.io/img/BIDV.png" />
                                                                </li>
                                                            </ul>
                                                            <ul className="tab-navigation tab-game tab-bank">
                                                                <li className={activeOption2 === "MBBANK" ? "active" : ""} onClick={() => handleOptionClick2("MBBANK")}>
                                                                    <img src="https://api.vietqr.io/img/MB.png" />
                                                                </li>
                                                                <li className={activeOption2 === "TPBANK" ? "active" : ""} onClick={() => handleOptionClick2("TPBANK")}>
                                                                    <img src="https://api.vietqr.io/img/TPB.png" />
                                                                </li>
                                                                <li className={activeOption2 === "VIB" ? "active" : ""} onClick={() => handleOptionClick2("VIB")}>
                                                                    <img src="https://api.vietqr.io/img/VIB.png" />
                                                                </li>
                                                                <li className={activeOption2 === "MOMO" ? "active" : ""} onClick={() => handleOptionClick2("MOMO")}>
                                                                    <img src="/images/logo_momo.png" />
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    )}
                                                    
                                                    {/* <div data-v-54a0cefc className="bankWrap activeDiv" bis_skin_checked={1}>
                                                        <div data-v-54a0cefc className="imgDiv" bis_skin_checked={1}>
                                                            <div data-v-54a0cefc className="imgDivBg" bis_skin_checked={1}>
                                                                <img data-v-54a0cefc src="/server/static/img/bank/acb.png?v=1.8.22" />
                                                            </div>
                                                        </div>
                                                        <p data-v-54a0cefc> ACB </p>
                                                    </div>
                                                    <div data-v-54a0cefc className="bankWrap" bis_skin_checked={1}>
                                                        <div data-v-54a0cefc className="imgDiv" bis_skin_checked={1}>
                                                            <div data-v-54a0cefc className="imgDivBg" bis_skin_checked={1}>
                                                                <img data-v-54a0cefc src="/server/static/img/bank/mb_bank.png?v=1.8.22" />
                                                            </div>
                                                        </div>
                                                        <p data-v-54a0cefc> MBbank </p>
                                                    </div>
                                                    <div data-v-54a0cefc className="bankWrap" bis_skin_checked={1}>
                                                        <div data-v-54a0cefc className="imgDiv" bis_skin_checked={1}>
                                                            <div data-v-54a0cefc className="imgDivBg" bis_skin_checked={1}>
                                                                <img data-v-54a0cefc src="/server/static/img/bank/msb.png?v=1.8.22" />
                                                            </div>
                                                        </div>
                                                        <p data-v-54a0cefc> MSB </p>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div data-v-54a0cefc className="promptItem" bis_skin_checked={1}>
                                            <p>Hướng dẫn nạp tiền:&nbsp;</p>
                                            <p>
                                                1. Chuyển khoản đến thông tin ngân hàng/Momo ở trên.
                                            
                                            </p>
                                            <p>2. Vui lòng ghi đúng nội dung chuyển khoản gồm có cả tên đăng nhập của bạn.&nbsp;</p>
                                            <p>3. Sau khi chuyển khoản thành công quý khách điền số tiền đã chuyển khoản vào ô "Nhập số tiền" và<br/> 
                                            bấm xác nhận, tiền sẽ được cộng trong vòng 5 phút.</p>
                                            <p>4. Nếu sau 5 phút tiền không tự động cộng vào tài khoản quý khách, vui lòng liên hệ CSKH để được<br/> 
                                            hỗ trợ nhanh nhất.</p>
                                        </div>
                                    </form>
                                    <br />
                                    {bank && (
                                        <>
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <div>
                                                    <div className="input">
                                                        <div className="text-dark" style={{fontSize: 14}}>Số tiền:</div>
                                                        {/* <input
                                                            className="ipadd"
                                                            type="text"
                                                            value={newMoney}
                                                            {...register("money")}
                                                            onClick={() => setNewMoney(null)}
                                                            onChange={(e) => setNewMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString("vi-VN"))}
                                                        /> */}
                                                        <input value={newMoney}
                                                            {...register("money")}
                                                            onClick={() => setNewMoney(null)}
                                                            onChange={(e) => setNewMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString("vi-VN"))} type="text" autocomplete="off" placeholder="100,000-300,000,000" className="el-input__inner"></input>
                                                        <div>
                                                            <button data-v-54a0cefc type="submit" className="btn-submit el-button el-button--primary el-button--small submit-recharge">
                                                                Nạp ngay
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: "none" }}>
                                                        <input className="ipadd" type="text" {...register("detail")} placeholder="Nhập mã giao dịch ngân hàng" defaultValue="Nạp" />
                                                    </div>
                                                </div>
                                            </form>

                                            {/* <div className="huongdan">
                                                <div className="title">Hướng dẫn nạp tiền</div>
                                                <ul>
                                                    <li>Chuyển khoản đến thông tin ngân hàng/Momo ở trên.</li>
                                                    <li>Vui lòng ghi đúng nội dung chuyển khoản gồm có cả tên đăng nhập của bạn</li>
                                                    <li>
                                                        Sau khi chuyển khoản thành công quý khách điền số tiền đã chuyển khoản vào ô "Nhập số tiền" và bấm xác nhận, tiền sẽ được cộng trong
                                                        vòng 5 phút.
                                                    </li>
                                                    <li>Nếu sau 5 phút tiền không tự động cộng vào tài khoản quý khách, vui lòng liên hệ CSKH để được hỗ trợ nhanh nhất.</li>
                                                </ul>
                                            </div> */}
                                        </>
                                    )}
                                </div>
                            }
                            {activeTab=== 2 && <>
                                <div className="content_bank">
                                    {bank &&
                                        bank?.map((item) =>
                                            item.isShow && item.name_bank.toLowerCase() != "momo" ? (
                                                <div className="item-bank" style={{ width: `${item?.qr ? "90%" : null}` }}>
                                                    {item.qr && (
                                                        <div>
                                                            <img src={item.qr} style={{ marginRight: "15px", width: "150px", float: "left" }} />
                                                        </div>
                                                    )}
                                                    <div>
                                                        Tên ngân hàng: <b style={{ color: "red" }}>{item.name_bank}</b>
                                                    </div>
                                                    <div>
                                                        Số tài khoản: <b>{item.stk}</b>
                                                    </div>
                                                    <div>
                                                        Tên người nhận: <b>{item.fullname}</b>
                                                    </div>
                                                    <div>
                                                        Nội dung chuyển khoản:{" "}
                                                        <b>
                                                            {item.title} {profile?.username}
                                                        </b>
                                                    </div>
                                                </div>
                                            ) : null
                                        )}
                                </div>
                            </>}
                            {activeTab=== 3 && 
                                <div className="content_bank">
                                    {bank &&
                                        bank?.map((item) =>
                                            item.isShow && item.name_bank.toLowerCase() == "momo" ? (
                                                <div className="item-bank" style={{ width: `${item?.qr ? "90%" : null}` }}>
                                                    {item.qr && (
                                                        <div>
                                                            <img src={item.qr} style={{ marginRight: "15px", width: "150px", float: "left" }} />
                                                        </div>
                                                    )}
                                                    <div>
                                                        Số Momo: <b>{item.stk}</b>
                                                    </div>
                                                    <div>
                                                        Tên người nhận: <b>{item.fullname}</b>
                                                    </div>
                                                    <div>
                                                        Nội dung chuyển khoản:{" "}
                                                        <b>
                                                            {item.title} {profile?.username}
                                                        </b>
                                                    </div>
                                                </div>
                                            ) : null
                                        )}
                                </div>}
                        </div>
                    </div>
                </div>
            
            <div className="recharge">
                {profile && (
                    <>
                        {/* <div className="menu-recharge">
                            <div className={`menu-item-recharge ${activeOption == 0 ? "active" : ""}`} onClick={() => handleOptionClick(0)}>
                                <img src={require("../../../image/onlinebanking.png")} />
                                <span>Nạp tiền siêu tốc</span>
                            </div>
                            <div className={`menu-item-recharge ${activeOption == 1 ? "active" : ""}`} onClick={() => handleOptionClick(1)}>
                                <img src={require("../../../image/banktransfer.png")} />
                                <span>Ngân hàng nội địa</span>
                            </div>
                            <div className={`menu-item-recharge ${activeOption == 2 ? "active" : ""}`} onClick={() => handleOptionClick(2)}>
                                <img src={require("../../../image/momo.jpeg")} />
                                <span>Ví Momo</span>
                            </div>
                        </div> */}
                        {/* <div className="content-recharge">
                            
                            {activeOption == 1 && (
                                <div className="content_bank">
                                    {bank &&
                                        bank?.map((item) =>
                                            item.isShow && item.name_bank.toLowerCase() != "momo" ? (
                                                <div className="item-bank" style={{ width: `${item?.qr ? "90%" : null}` }}>
                                                    {item.qr && (
                                                        <div>
                                                            <img src={item.qr} style={{ marginRight: "15px", width: "150px", float: "left" }} />
                                                        </div>
                                                    )}
                                                    <div>
                                                        Tên ngân hàng: <b style={{ color: "red" }}>{item.name_bank}</b>
                                                    </div>
                                                    <div>
                                                        Số tài khoản: <b>{item.stk}</b>
                                                    </div>
                                                    <div>
                                                        Tên người nhận: <b>{item.fullname}</b>
                                                    </div>
                                                    <div>
                                                        Nội dung chuyển khoản:{" "}
                                                        <b>
                                                            {item.title} {profile?.username}
                                                        </b>
                                                    </div>
                                                </div>
                                            ) : null
                                        )}
                                </div>
                            )}
                            {activeOption == 2 && (
                                <div className="content_bank">
                                    {bank &&
                                        bank?.map((item) =>
                                            item.isShow && item.name_bank.toLowerCase() == "momo" ? (
                                                <div className="item-bank" style={{ width: `${item?.qr ? "90%" : null}` }}>
                                                    {item.qr && (
                                                        <div>
                                                            <img src={item.qr} style={{ marginRight: "15px", width: "150px", float: "left" }} />
                                                        </div>
                                                    )}
                                                    <div>
                                                        Số Momo: <b>{item.stk}</b>
                                                    </div>
                                                    <div>
                                                        Tên người nhận: <b>{item.fullname}</b>
                                                    </div>
                                                    <div>
                                                        Nội dung chuyển khoản:{" "}
                                                        <b>
                                                            {item.title} {profile?.username}
                                                        </b>
                                                    </div>
                                                </div>
                                            ) : null
                                        )}
                                </div>
                            )}
                            
                        </div> */}
                    </>
                )}
            </div>
        </>
    );
}
export default Recharge;
