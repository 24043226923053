import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import "./game.css";
import { Link, useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";

function Slot() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    function launchGame(params) {
        if (!localStorage.getItem("profile")) {
            swal("Thông báo", "Vui lòng đăng nhập", "info");
        }
        if (loading == true) return;
        const form = {
            ...params,
            mobile: isMobileDevice == true ? 1 : 0
        };
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/Seamless/LaunchGame`, form)
            .then((res) => {
                if (res.data.data.ErrorCode == 0) {
                    window.location.href = res.data.data.Url;
                } else {
                    swal("Sảnh đang bảo trì", "Vui lòng chọn sảnh khác", "error");
                }
                setLoading(false);
            })
            .catch((err) => {
                swal("Đã có lỗi xảy ra", err.response.data.message != "internal server error" ? err.response.data.message : "Vui lòng đăng xuất và đăng nhập lại", "error").then(
                    () => navigate("/login")
                );
                setLoading(false);
            });
    }
    return (
        <>
            {loading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <Box mt={3}>
                <div className="container">
                    <div className="banner-game" style={{ borderRadius: 10, overflow: "hidden" }}>
                        <img src={"https://yscduxhccunurys.com/home/static/img/slot_hot_banner.5bb55a2a.png"} style={{ borderRadius: 10, overflow: "hidden" }} />
                    </div>
                    <Box className="wrap-slots">
                        <Grid container>
                            <Grid item xs={3}>
                                <ul data-v-4ef38500  >
                                    
                                    <li data-v-4ef38500 className onClick={() => launchGame({ productid: "1091", gametype: "1" })}>
                                        <div data-v-4ef38500 className="ani-container" bis_skin_checked={1} style={{ textAlign: "left", display: "none" }}>
                                            <canvas width={1080} height={324} style={{ backgroundColor: "transparent" }} />
                                        </div>
                                        <div data-v-4ef38500 className="img_games_2" bis_skin_checked={1}>
                                            <div data-v-4ef38500 className="actionDom" bis_skin_checked={1} />
                                            <img data-v-4ef38500 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/slot/sub/jili.png?v=1.8.22" alt="" className="subImg jili" />
                                            <div data-v-4ef38500 className="name" bis_skin_checked={1}>
                                                <p data-v-4ef38500 className="nametext">
                                                    JILI NỔ HŨ
                                                </p>
                                                <p data-v-4ef38500 className="namesub">
                                                    Những điều bất ngờ thú vị
                                                </p>
                                            </div>
                                            <div data-v-4ef38500 className="circleLogo" bis_skin_checked={1}>
                                                <img data-v-4ef38500 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/circle_icon/jili.png?v=1.8.22" alt="" />
                                            </div>
                                        </div>
                                        {/**/}
                                    </li>
                                    <li data-v-4ef38500 className onClick={() => launchGame({ productid: "1007", gametype: "1" })}>
                                        <div data-v-4ef38500 className="ani-container" bis_skin_checked={1} style={{ display: "none" }} />
                                        <div data-v-4ef38500 className="img_games_3" bis_skin_checked={1}>
                                            <div data-v-4ef38500 className="actionDom" bis_skin_checked={1} />
                                            <img data-v-4ef38500 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/slot/sub/pg.png?v=1.8.22" alt="" className="subImg pgsoft" />
                                            <div data-v-4ef38500 className="name" bis_skin_checked={1}>
                                                <p data-v-4ef38500 className="nametext">
                                                    PG NỔ HŨ
                                                </p>
                                                <p data-v-4ef38500 className="namesub">
                                                    Những điều bất ngờ thú vị
                                                </p>
                                            </div>
                                            <div data-v-4ef38500 className="circleLogo" bis_skin_checked={1}>
                                                <img data-v-4ef38500 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/circle_icon/pgsoft.png?v=1.8.22" alt="" />
                                            </div>
                                        </div>
                                        {/**/}
                                    </li>

                                    
                                    <li data-v-4ef38500 className onClick={() => launchGame({ productid: "1011", gametype: "1" })}>
                                        <div data-v-4ef38500 className="ani-container" bis_skin_checked={1} style={{ display: "none" }} />
                                        <div data-v-4ef38500 className="img_games_6" bis_skin_checked={1}>
                                            <div data-v-4ef38500 className="actionDom" bis_skin_checked={1} />
                                            <img data-v-4ef38500 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/slot/sub/mg.png?v=1.8.22" alt="" className="subImg mg" />
                                            <div data-v-4ef38500 className="name" bis_skin_checked={1}>
                                                <p data-v-4ef38500 className="nametext">
                                                        PLAYTECH NỔ HŨ
                                                </p>
                                                <p data-v-4ef38500 className="namesub">
                                                    Những điều bất ngờ thú vị
                                                </p>
                                            </div>
                                            <div data-v-4ef38500 className="circleLogo" bis_skin_checked={1}>
                                                <img data-v-4ef38500 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/circle_icon/mg.png?v=1.8.22" alt="" />
                                            </div>
                                        </div>
                                        {/**/}
                                    </li>
                                    <li data-v-4ef38500 className onClick={() => launchGame({ productid: "1085", gametype: "1" })}>
                                        <div data-v-4ef38500 className="ani-container" bis_skin_checked={1} style={{ display: "none" }} />
                                        <div data-v-4ef38500 className="img_games_7" bis_skin_checked={1}>
                                            <div data-v-4ef38500 className="actionDom" bis_skin_checked={1} />
                                            <img data-v-4ef38500 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/slot/sub/jdb.png?v=1.8.22" alt="" className="subImg jdb" />
                                            <div data-v-4ef38500 className="name" bis_skin_checked={1}>
                                                <p data-v-4ef38500 className="nametext">
                                                    JDB NỔ HŨ
                                                </p>
                                                <p data-v-4ef38500 className="namesub">
                                                    Những điều bất ngờ thú vị
                                                </p>
                                            </div>
                                            <div data-v-4ef38500 className="circleLogo" bis_skin_checked={1}>
                                                <img data-v-4ef38500 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/circle_icon/jdb.png?v=1.8.22" alt="" />
                                            </div>
                                        </div>
                                        {/**/}
                                    </li>
                                    
                                    <li data-v-4ef38500 className onClick={() => launchGame({ productid: "1009", gametype: "1" })}>
                                        <div data-v-4ef38500 className="ani-container" bis_skin_checked={1} style={{ display: "none" }} />
                                        <div data-v-4ef38500 className="img_games_9" bis_skin_checked={1}>
                                            <div data-v-4ef38500 className="actionDom" bis_skin_checked={1} />
                                            <img data-v-4ef38500 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/slot/sub/cq9.png?v=1.8.22" alt="" className="subImg cq9" />
                                            <div data-v-4ef38500 className="name" bis_skin_checked={1}>
                                                <p data-v-4ef38500 className="nametext">
                                                    CQ9 NỔ HŨ
                                                </p>
                                                <p data-v-4ef38500 className="namesub">
                                                    Những điều bất ngờ thú vị
                                                </p>
                                            </div>
                                            <div data-v-4ef38500 className="circleLogo" bis_skin_checked={1}>
                                                <img data-v-4ef38500 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/circle_icon/cq9.png?v=1.8.22" alt="" />
                                            </div>
                                        </div>
                                        {/**/}
                                    </li>
                                    
                                </ul>
                            </Grid>
                            <Grid item xs={9}></Grid>
                        </Grid>
                    </Box>
                    {/* <div className="menu-box-game" style={{ gridTemplateColumns: "1fr 1fr 1fr" }}>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/jili.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ productid: "1091", gametype: "1" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/jdb.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ productid: "1085", gametype: "1" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/pg.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ productid: "1007", gametype: "1" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/pp.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ productid: "1006", gametype: "1" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/cq9.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ productid: "1009", gametype: "1" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/pt.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ productid: "1011", gametype: "1" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                    </div> */}
                </div>
            </Box>
        </>
    );
}
export default Slot;
