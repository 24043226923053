import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./history.css";
import swal from "sweetalert";

function Historybet() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [history, setHistory] = useState(null);
    const [historyGame, setHistoryGame] = useState(null);
    const [isShow, setShow] = useState(false);
    const [ls, setLs] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/history/historyus`, {})
            .then((res) => {
                setHistory(res.data.data);
            })
            .catch((err) => console.log(err));
    }, []);
    const XSMN = [
        "Bạc Liêu",
        "Vũng Tàu",
        "Tiền Giang",
        "Kiên Giang",
        "Đà Lạt",
        "Bình Phước",
        "Bình Dương",
        "An Giang",
        "Bình Thuận",
        "Cà Mau",
        "Cần Thơ",
        "Hậu Giang",
        "Đồng Tháp",
        "Tây Ninh",
        "Vĩnh Long",
        "Trà Vinh",
        "Sóc Trăng",
        "Long An",
        "TP. HCM",
        "Đồng Nai",
        "Bến Tre"
    ];
    const XSMT = [
        "Đà Nẵng",
        "Thừa T. Huế",
        "Quảng Trị",
        "Phú Yên",
        "Quảng Bình",
        "Quảng Nam",
        "Quảng Ngãi",
        "Ninh Thuận",
        "Kon Tum",
        "Khánh Hòa",
        "Gia Lai",
        "Bình Định",
        "Đắk Lắk",
        "Đắk Nông"
    ];
    const checkCancel = (item) => {
        const datexs = new Date(item.id_bet);
        const ngayxs = datexs.getDate();
        const thangxs = datexs.getMonth() + 1;
        const namxs = datexs.getFullYear();
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        const formattedDate = `${day < 10 ? "0" + day : day}/${month < 10 ? "0" + month : month}/${year}`;
        const minute = currentDate.getMinutes() < 10 ? "0" + currentDate.getMinutes() : currentDate.getMinutes();

        if (item.sanh === "XSMB" && item?.id_bet == formattedDate && Number(currentDate.getHours() + "" + minute) >= 1800) {
            console.log("a");
            return 0;
        } else if (XSMT.includes(item?.sanh) && item?.id_bet == formattedDate && Number(currentDate.getHours() + "" + minute) >= 1700) {
            console.log("b");
            return 0;
        } else if (XSMN.includes(item?.sanh) && item?.id_bet == formattedDate && Number(currentDate.getHours() + "" + minute) >= 1600) {
            console.log("c");
            return 0;
        } else {
            return 1;
        }
    };
    return (
        <>
            <div className="historyrecharge">
                <div data-v-2533025a data-v-54a0cefc className="cardInfo noMargin" bis_skin_checked={1}>
                    <div data-v-2533025a className="cardTitle" bis_skin_checked={1}>
                        <span data-v-2533025a className="title">
                            Lịch sử trò chơi
                        </span>
                    </div>
                    <div data-v-2533025a className="cardBody paddingTop0 padding20" bis_skin_checked={1}>
                        {history ? (
                            <div className="main-history">
                                <div className="header-history">
                                    <div style={{ width: "10%" }}>Kỳ</div>
                                    <div style={{ width: "10%" }}>Sảnh</div>
                                    <div style={{ width: "30%" }}>Lựa chọn</div>
                                    <div style={{ width: "10%" }}>Số tiền cược</div>
                                    <div style={{ width: "10%" }}>Số tiền thắng</div>
                                    <div style={{ width: "15%" }}>Trạng thái</div>
                                    <div style={{ width: "15%" }}>Thời gian</div>
                                </div>
                                {history?.map((item, key) => (
                                    <>
                                        <div className="content-history">
                                            <div style={{ width: "10%" }}>{item?.id_bet?.id_bet ? item?.id_bet?.id_bet : item?.id_bet}</div>
                                            <div style={{ width: "10%" }}>{item?.sanh}</div>
                                            <div style={{ width: "30%", color: "red" }}>{item?.state}</div>
                                            <div style={{ width: "10%" }}>{item?.money?.toLocaleString("vi-VN")}</div>
                                            <div style={{ width: "10%" }}>{item?.moneythang?.toLocaleString("vi-VN")}</div>
                                            <div style={{ width: "15%" }}>
                                                <span
                                                    className={`text_status ${
                                                        item.status_bet === "Pending"
                                                            ? "pending"
                                                            : item.status_bet === "Win"
                                                            ? "win"
                                                            : item.status_bet === "Lose"
                                                            ? "lose"
                                                            : "cancelled"
                                                    }`}>
                                                    {item.status_bet === "Pending"
                                                        ? "Chờ xổ"
                                                        : item.status_bet === "Win"
                                                        ? "Thắng"
                                                        : item.status_bet === "Lose"
                                                        ? "Thua"
                                                        : "Đã hủy"}
                                                </span>
                                                {item?.status_bet === "Pending" && (
                                                    <>
                                                        {(item?.sanh == "XSMB" || XSMN.includes(item?.sanh) || XSMT.includes(item?.sanh)) && (
                                                            <>
                                                                {checkCancel(item) == 1 && (
                                                                    <span
                                                                        className="cancel-bet text_status"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.target.style.display = "none";
                                                                            const currentDate = new Date();
                                                                            const day = currentDate.getDate();
                                                                            const month = currentDate.getMonth() + 1;
                                                                            const year = currentDate.getFullYear();
                                                                            const formattedDate = `${day < 10 ? "0" + day : day}/${month < 10 ? "0" + month : month}/${year}`;
                                                                            const minute =
                                                                                currentDate.getMinutes() < 10 ? "0" + currentDate.getMinutes() : currentDate.getMinutes();
                                                                            if (
                                                                                item.sanh === "XSMB" &&
                                                                                Number(currentDate.getHours() + "" + minute) >= 1800 &&
                                                                                Number(currentDate.getHours() + "" + minute) < 1915
                                                                            ) {
                                                                                swal("Thông báo", "Chỉ được hủy cược trước 30p xổ", "error");
                                                                                e.stopPropagation();
                                                                                return;
                                                                            } else if (
                                                                                XSMT.includes(item?.sanh) &&
                                                                                item?.id_bet == formattedDate &&
                                                                                Number(currentDate.getHours() + "" + minute) >= 1700 &&
                                                                                Number(currentDate.getHours() + "" + minute) < 1815
                                                                            ) {
                                                                                swal("Thông báo", "Chỉ được hủy cược trước 30p xổ", "error");
                                                                                e.stopPropagation();
                                                                                return;
                                                                            } else if (
                                                                                XSMN.includes(item?.sanh) &&
                                                                                item?.id_bet == formattedDate &&
                                                                                Number(currentDate.getHours() + "" + minute) >= 1600 &&
                                                                                Number(currentDate.getHours() + "" + minute) < 1715
                                                                            ) {
                                                                                swal("Thông báo", "Chỉ được hủy cược trước 30p xổ", "error");
                                                                                e.stopPropagation();
                                                                                return;
                                                                            }
                                                                            axios
                                                                                .patch(`${process.env.REACT_APP_API_URL}/history/cancel`, {
                                                                                    id: item?._id,
                                                                                    userId: item?.user?._id,
                                                                                    money: item?.money
                                                                                })
                                                                                .then((res) => {
                                                                                    setShow(false);
                                                                                    swal("Hủy cược thành công", "", "success").then((value) => {
                                                                                        axios
                                                                                            .get(`${process.env.REACT_APP_API_URL}/history/historyus`, {})
                                                                                            .then((res) => {
                                                                                                setHistoryGame(res.data.data);
                                                                                            })
                                                                                            .catch((err) => {});
                                                                                    });
                                                                                })
                                                                                .catch((err) => {
                                                                                    setShow(false);
                                                                                    swal("Thông báo", "Đã xảy ra lỗi, vui lòng thử lại!", "error");
                                                                                    e.target.style.display = "inline-block";
                                                                                });
                                                                        }}>
                                                                        &times; Hủy cược
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            <div style={{ width: "15%" }}>{formatDate(new Date(item?.createdAt))}</div>
                                        </div>
                                    </>
                                ))}
                                {history?.length <= 0 && (
                                <div className="el-table__empty-block" style={{ height: "100%", width: "100%" }} bis_skin_checked={1}>
                                    <span className="el-table__empty-text">
                                        <p data-v-2b2c2fe2 className="noData">
                                            Hiện không có chi tiết giao dịch
                                        </p>
                                    </span>
                                </div>
                            )}
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </div>
                {/* <div className="top_title">
                    <div className="txt">Lịch sử trò chơi</div>
                </div> */}
            </div>
        </>
    );
}
export default Historybet;
