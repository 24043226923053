import React from "react";
import "./popupmenu.css";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import Account from "../user/account/Account";
import Recharge from "../user/recharge/Recharge";
import Withdraw from "../user/withdraw/WithDraw";
import HistoryRecharge from "../user/history/HistoryRecharge";
import HistoryWithdraw from "../user/history/HistoryWithdraw";
import HistoryBet from "../user/history/HistoryBet";
import Transfer from "../user/transfer/Transfer";
import axios from "axios";
import moment from "moment";

const PopupMenu = ({ profile, isOpen, isClose, menuActive, getProfile }) => {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [activeOption, setActiveOption] = useState(0);
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    useEffect(() => {
        setActiveOption(menuActive);
    }, [menuActive]);
    const [bankCard, setBankCard] = useState(false);
    const menuClick = () => {
        setActiveOption(3);
        setBankCard(true);
    };
    return (
        <>
            {isOpen === true && profile != null && (
                <div className="backdrop">
                    <div className="menu-popup">
                        <div className="menu-popup-grid">
                            <div className="menu-sidebar" style={{ marginRight: 10 }}>
                                <div className="menu-title">Thông tin tài khoản</div>
                                <div data-v-535fd478 className="userInfoBox" bis_skin_checked={1}>
                                    <div data-v-535fd478 bis_skin_checked={1}>
                                        <span data-v-535fd478 className="avatar el-avatar el-avatar--circle">
                                            <img src="https://yscduxhccunurys.com/server/static/default_avatar/12.png?v=1.8.22" style={{ objectFit: "cover" }} />
                                        </span>
                                    </div>
                                    <div data-v-535fd478 className="info" bis_skin_checked={1}>
                                        <div data-v-535fd478 className="userName" bis_skin_checked={1}>
                                            <span data-v-535fd478> {profile?.username}</span>
                                            <i data-v-535fd478 className="iconfont icon-icon_amend_name cursorP" />
                                        </div>
                                        <p data-v-535fd478 className="jionDay">
                                            {" "}
                                            Gia nhập ngày thứ {moment().diff(moment(profile?.createdAt), 'days')}{" "}
                                        </p>
                                    </div>
                                </div>
                                {/*  */}
                                <div data-v-535fd478 className="userMoney" bis_skin_checked={1}>
                                    <div data-v-535fd478 className="money" bis_skin_checked={1}>
                                        <span data-v-535fd478 className="totalMoney">
                                            Tổng số dư:{" "}
                                        </span>
                                        <br data-v-535fd478 />
                                        <span data-v-535fd478>{profile?.money}</span>
                                        <i data-v-535fd478 className="iconfont icon-icon_refresh_gold_12" />
                                    </div>
                                    <div data-v-535fd478 className="money nothemeMoney" style={{ display: "none" }} bis_skin_checked={1}>
                                        <div data-v-535fd478 bis_skin_checked={1}>
                                            {profile?.money}
                                            {/* {console.log("profile", profile)} */}
                                        </div>
                                        <span data-v-535fd478> Tổng số dư </span> &nbsp;&nbsp; <i data-v-535fd478 className="iconfont icon-icon_refresh_gold_12" />
                                    </div>
                                    <div data-v-535fd478 className="payInfo" bis_skin_checked={1}>
                                        <div onClick={()=> {handleOptionClick(2)}} data-v-535fd478 bis_skin_checked={1}>
                                            <img data-v-535fd478 src="https://yscduxhccunurys.com/static/img/icon-topup.b7d5be80.svg" alt="" />
                                            <span data-v-535fd478>Nạp tiền</span>
                                        </div>
                                        <div onClick={()=> {handleOptionClick(7)}} data-v-535fd478 bis_skin_checked={1}>
                                            <img data-v-535fd478 src="https://yscduxhccunurys.com/static/img/icon-wallet.54c179e8.svg" alt="" className="transferImg" />
                                            <span data-v-535fd478>Chuyển quỹ</span>
                                        </div>
                                        <div onClick={()=> {handleOptionClick(3)}} data-v-535fd478 bis_skin_checked={1}>
                                            <img data-v-535fd478 src="https://yscduxhccunurys.com/static/img/icon-withdraw.7122f604.svg" alt="" />
                                            <span data-v-535fd478>Rút tiền</span>
                                        </div>
                                    </div>
                                    <div data-v-535fd478 className="payInfo" style={{ display: "none" }} bis_skin_checked={1}>
                                        <div data-v-535fd478 className="payInfoSpan" bis_skin_checked={1}>
                                            <span data-v-535fd478>Nạp tiền</span>
                                        </div>
                                        <div data-v-535fd478 className="payInfoSpan" bis_skin_checked={1}>
                                            <span data-v-535fd478>Rút tiền</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="menu-sidebar-list">
                                    <ul>
                                        <li onClick={() => handleOptionClick(1)} className={`menu-sidebar-item ${activeOption == 1 ? "active" : ""}`}>
                                            <i className="acmc_icon icon_securityCenter"></i>
                                            <span>Tài khoản</span>
                                        </li>
                                        <li onClick={() => handleOptionClick(7)} className={`menu-sidebar-item ${activeOption == 7 ? "active" : ""}`}>
                                            <i className="acmc_icon icon_rewardCenter"></i>
                                            <span>Chuyển ví</span>
                                        </li>
                                        <li onClick={() => handleOptionClick(2)} className={`menu-sidebar-item ${activeOption == 2 ? "active" : ""}`}>
                                            <i className="acmc_icon icon_depositv2"></i>
                                            <span>Nạp tiền</span>
                                        </li>
                                        <li onClick={() => handleOptionClick(3)} className={`menu-sidebar-item ${activeOption == 3 ? "active" : ""}`}>
                                            <i className="acmc_icon icon_withdraw"></i>
                                            <span>Rút tiền</span>
                                        </li>
                                        <li onClick={() => handleOptionClick(4)} className={`menu-sidebar-item ${activeOption == 4 ? "active" : ""}`}>
                                            <i className="acmc_icon icon_personalHistoryCopy"></i>
                                            <span>Lịch sử trò chơi</span>
                                        </li>
                                        <li onClick={() => handleOptionClick(5)} className={`menu-sidebar-item ${activeOption == 5 ? "active" : ""}`}>
                                            <i className="acmc_icon icon_transactionHistory"></i>
                                            <span>Lịch sử nạp tiền</span>
                                        </li>
                                        <li onClick={() => handleOptionClick(6)} className={`menu-sidebar-item ${activeOption == 6 ? "active" : ""}`}>
                                            <i className="acmc_icon icon_personalProfit"></i>
                                            <span>Lịch sử rút tiền</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="menu-content">
                                <div className="menu-content-box">  
                                    <div className="newTheme">
                                        {activeOption == 1 && <Account profile={profile} menuClick={menuClick} getProfile={getProfile} />}
                                        {activeOption == 2 && <Recharge profile={profile} getProfile={getProfile} />}
                                        {activeOption == 3 && <Withdraw profile={profile} bankCard={bankCard} getProfile={getProfile} />}
                                        {activeOption == 4 && <HistoryBet profile={profile} getProfile={getProfile} />}
                                        {activeOption == 5 && <HistoryRecharge profile={profile} getProfile={getProfile} />}
                                        {activeOption == 6 && <HistoryWithdraw profile={profile} getProfile={getProfile} />}
                                        {activeOption == 7 && <Transfer profile={profile} getProfile={getProfile} />}
                                    </div>
                                </div>
                                <div className="close-popup" onClick={isClose}>
                                    <CloseIcon />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default PopupMenu;
