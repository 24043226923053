import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import "./game.css";
import { Link, useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";

function Fishing() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    function launchGame(params) {
        if (!localStorage.getItem("profile")) {
            swal("Thông báo", "Vui lòng đăng nhập", "info");
        }
        if (loading == true) return;
        const form = {
            ...params,
            mobile: isMobileDevice == true ? 1 : 0
        };
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/Seamless/LaunchGame`, form)
            .then((res) => {
                if (res.data.data.ErrorCode == 0) {
                    window.location.href = res.data.data.Url;
                } else {
                    swal("Sảnh đang bảo trì", "Vui lòng chọn sảnh khác", "error");
                }
                setLoading(false);
            })
            .catch((err) => {
                swal("Đã có lỗi xảy ra", err.response.data.message != "internal server error" ? err.response.data.message : "Vui lòng đăng xuất và đăng nhập lại", "error").then(
                    () => navigate("/login")
                );
                setLoading(false);
            });
    }
    return (
        <>
            {loading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            {/* <div className="banner-game">
                <img src={require("../../image/fishing.jpg")} />
            </div> */}
            <Box className="wrap-theme-light" sx={{background: "#2a2f3b"}}>
                <Box className="wrap-fishing-page" sx={{display: "flex", justifyContent: "center"}} pt={3}>
                    <Box sx={{height: 816, width: "100%"}} maxWidth={1400}>
                        <Box className="wrap-fish-page" sx={{backgroundImage: "url(https://yscduxhccunurys.com/home/static/img/img_fish_bg.a789e556.png)", height: "100%", backgroundSize: "contain", backgroundRepeat: "no-repeat", position: "absolute", left: 0, width: "100%"}}>
                            
                        </Box>
                        <Grid container sx={{position: "relative", zIndex: 2}}>
                            <Grid item xs={6}>

                            </Grid>
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid className="item-image-fishing" item xs={4}>
                                        <img onClick={() => launchGame({ productid: "1091", gametype: "8" })} className="scale-1-25" style={{width: '200px'}} alt="Can't open" src="https://yscduxhccunurys.com/server/static/img/thirdgame/pc/fish_jilifish_main.png" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <img onClick={() => launchGame({ productid: "1004", gametype: "8" })} className="scale-1-25" style={{width: '200px'}} alt="Can't open" src="https://yscduxhccunurys.com/server/static/img/thirdgame/pc/fish_mgfish_main.png" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    {/* <div className="menu-box-game" style={{ gridTemplateColumns: "1fr 1fr" }}>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/JILI_bc.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ productid: "1091", gametype: "8" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/MG_bc.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ productid: "1004", gametype: "8" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                    </div> */}
                </Box>
            </Box>
        </>
    );
}
export default Fishing;
