import React from "react";
import "./menumegagambling.css"

const Megamenugambling = (props) => {
    return (
        <div data-v-1936baa7 className="realGamer chess" bis_skin_checked={1}  style={{maxHeight: props?.maxHeight || 0}}>
            <ul data-v-1936baa7 className>
                <li data-v-1936baa7 className="gameItem last-row-item">
                    <div data-v-12798650 data-v-1936baa7 className="navPlatItem chess" bis_skin_checked={1}>
                        <div data-v-12798650 className="cover" bis_skin_checked={1}>
                            <div data-v-12798650 className="btn-play" bis_skin_checked={1}>
                                <div data-v-12798650 className="icon-play" bis_skin_checked={1} />
                                <div data-v-12798650 className="btn-txt" bis_skin_checked={1}>
                                    PLAY NOW
                                </div>
                            </div>
                        </div>
                        <p data-v-12798650 className="line" />
                        <div data-v-12798650 className="name" bis_skin_checked={1}>
                            <p data-v-12798650>V8</p>
                        </div>
                        <div data-v-12798650 className="imgContainer chess" bis_skin_checked={1}>
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/chess/croupier/v8.png?v=1.8.22" alt="" className="main" />
                            {/**/}
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/chess/foreground/v8.png?v=1.8.22" alt="" className="bot" />
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/v8.png?v=1.8.22" alt="" className="logo v8" />
                        </div>
                        {/**/}
                    </div>
                </li>
                <li data-v-1936baa7 className="gameItem last-row-item">
                    <div data-v-12798650 data-v-1936baa7 className="navPlatItem chess" bis_skin_checked={1}>
                        <div data-v-12798650 className="cover" bis_skin_checked={1}>
                            <div data-v-12798650 className="btn-play" bis_skin_checked={1}>
                                <div data-v-12798650 className="icon-play" bis_skin_checked={1} />
                                <div data-v-12798650 className="btn-txt" bis_skin_checked={1}>
                                    PLAY NOW
                                </div>
                            </div>
                        </div>
                        <p data-v-12798650 className="line" />
                        <div data-v-12798650 className="name" bis_skin_checked={1}>
                            <p data-v-12798650>RICH88 CHESS</p>
                        </div>
                        <div data-v-12798650 className="imgContainer chess" bis_skin_checked={1}>
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/chess/croupier/rich88.png?v=1.8.22" alt="" className="main" />
                            {/**/}
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/chess/foreground/rich88.png?v=1.8.22" alt="" className="bot" />
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/rich88.png?v=1.8.22" alt="" className="logo rich88chess" />
                        </div>
                        {/**/}
                    </div>
                </li>
                <li data-v-1936baa7 className="gameItem last-row-item">
                    <div data-v-12798650 data-v-1936baa7 className="navPlatItem chess" bis_skin_checked={1}>
                        <div data-v-12798650 className="cover" bis_skin_checked={1}>
                            <div data-v-12798650 className="btn-play" bis_skin_checked={1}>
                                <div data-v-12798650 className="icon-play" bis_skin_checked={1} />
                                <div data-v-12798650 className="btn-txt" bis_skin_checked={1}>
                                    PLAY NOW
                                </div>
                            </div>
                        </div>
                        <p data-v-12798650 className="line" />
                        <div data-v-12798650 className="name" bis_skin_checked={1}>
                            <p data-v-12798650>KY</p>
                        </div>
                        <div data-v-12798650 className="imgContainer chess" bis_skin_checked={1}>
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/chess/croupier/ky.png?v=1.8.22" alt="" className="main" />
                            {/**/}
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/chess/foreground/ky.png?v=1.8.22" alt="" className="bot" />
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/ky.png?v=1.8.22" alt="" className="logo ky" />
                        </div>
                        {/**/}
                    </div>
                </li>
                <li data-v-1936baa7 className="gameItem last-row-item">
                    <div data-v-12798650 data-v-1936baa7 className="navPlatItem chess" bis_skin_checked={1}>
                        <div data-v-12798650 className="cover" bis_skin_checked={1}>
                            <div data-v-12798650 className="btn-play" bis_skin_checked={1}>
                                <div data-v-12798650 className="icon-play" bis_skin_checked={1} />
                                <div data-v-12798650 className="btn-txt" bis_skin_checked={1}>
                                    PLAY NOW
                                </div>
                            </div>
                        </div>
                        <p data-v-12798650 className="line" />
                        <div data-v-12798650 className="name" bis_skin_checked={1}>
                            <p data-v-12798650>JILI GAME BÀI</p>
                        </div>
                        <div data-v-12798650 className="imgContainer chess" bis_skin_checked={1}>
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/chess/croupier/jili.png?v=1.8.22" alt="" className="main" />
                            {/**/}
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/chess/foreground/jili.png?v=1.8.22" alt="" className="bot" />
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/jili.png?v=1.8.22" alt="" className="logo jilichess" />
                        </div>
                        {/**/}
                    </div>
                </li>
                <li data-v-1936baa7 className="gameItem last-row-item">
                    <div data-v-12798650 data-v-1936baa7 className="navPlatItem chess" bis_skin_checked={1}>
                        <div data-v-12798650 className="cover" bis_skin_checked={1}>
                            <div data-v-12798650 className="btn-play" bis_skin_checked={1}>
                                <div data-v-12798650 className="icon-play" bis_skin_checked={1} />
                                <div data-v-12798650 className="btn-txt" bis_skin_checked={1}>
                                    PLAY NOW
                                </div>
                            </div>
                        </div>
                        <p data-v-12798650 className="line" />
                        <div data-v-12798650 className="name" bis_skin_checked={1}>
                            <p data-v-12798650>MG GAME BÀI</p>
                        </div>
                        <div data-v-12798650 className="imgContainer chess" bis_skin_checked={1}>
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/chess/croupier/mg.png?v=1.8.22" alt="" className="main" />
                            {/**/}
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/chess/foreground/mg.png?v=1.8.22" alt="" className="bot" />
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/mg.png?v=1.8.22" alt="" className="logo mgchess" />
                        </div>
                        {/**/}
                    </div>
                </li>
                <li data-v-1936baa7 className="gameItem last-row-item">
                    <div data-v-12798650 data-v-1936baa7 className="navPlatItem chess" bis_skin_checked={1}>
                        <div data-v-12798650 className="cover" bis_skin_checked={1}>
                            <div data-v-12798650 className="btn-play" bis_skin_checked={1}>
                                <div data-v-12798650 className="icon-play" bis_skin_checked={1} />
                                <div data-v-12798650 className="btn-txt" bis_skin_checked={1}>
                                    PLAY NOW
                                </div>
                            </div>
                        </div>
                        <p data-v-12798650 className="line" />
                        <div data-v-12798650 className="name" bis_skin_checked={1}>
                            <p data-v-12798650>BBIN CHESS</p>
                        </div>
                        <div data-v-12798650 className="imgContainer chess" bis_skin_checked={1}>
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/chess/croupier/bbin.png?v=1.8.22" alt="" className="main" />
                            {/**/}
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/pc/chess/foreground/bbin.png?v=1.8.22" alt="" className="bot" />
                            <img data-v-12798650 src="https://yscduxhccunurys.com/server/static/img/thirdgame/imgs/platform_logo/long/bbin.png?v=1.8.22" alt="" className="logo bbinchess" />
                        </div>
                        {/**/}
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default Megamenugambling;
