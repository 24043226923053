import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

const emails = ["username@gmail.com", "user02@gmail.com"];

function SimpleDialog(props) {
    const { register, handleSubmit } = useForm();
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const onSubmitPass2 = (data) => {
        if (!data.oldpassword) {
            swal("Thông báo", "Vui lòng nhập mật khẩu cũ", "warning");
            return;
        }
        if (data.oldpassword.length < 6 || data.password.length < 6 || data.checkpassword.length < 6) {
            swal("Thông báo", "Mật khẩu tối thiểu 6 kí tự", "warning");
            return;
        }
        if (data.password != data.checkpassword) {
            swal("Thông báo", "Xác nhận mật khẩu mới không đúng", "error");
            return;
        }
        const formData = {
            password: data.oldpassword,
            newpassword: data.password
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/changepass2`, formData)
            .then((res) => {
                swal({
                    title: "Đổi mật khẩu rút tiền thành công",
                    text: "Vui lòng đăng nhập lại",
                    icon: "success",
                    buttons: "OK"
                }).then(() => logOut());
            })
            .catch((err) => swal("Thông báo", "Mật khẩu cũ không chính xác", "error"));
    };

    const logOut = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("currentUser");
        localStorage.removeItem("profile");
        localStorage.removeItem("data");
        localStorage.removeItem("data1");
        var now = new Date();
        var time = now.getTime();
        var expireTime = time;
        now.setTime(expireTime);
        document.cookie = "jwt=;Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        window.location.reload();
    };

    return (
        <Dialog
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none"
                }
            }}
            onClose={handleClose}
            open={open}>
            <div data-v-184a7108 data-v-429ecedc className="dialog" bis_skin_checked={1}>
                <div data-v-184a7108 className="el-dialog__wrapper verifyFormDialog" bis_skin_checked={1} style={{ zIndex: 2009 }}>
                    <div role="dialog" aria-modal="true" aria-label="Thay đổi mật khẩu giao dịch" className="el-dialog" bis_skin_checked={1}>
                        <div className="el-dialog__header" bis_skin_checked={1}>
                            <span className="el-dialog__title">Thay đổi mật khẩu giao dịch</span>
                            <button type="button" aria-label="Close" className="el-dialog__headerbtn">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
                            </button>
                        </div>
                        <div className="el-dialog__body" bis_skin_checked={1}>
                            <span data-v-184a7108 className="closeBtn">
                                <i data-v-184a7108 className="iconfont icon-icon_close_dark" />
                            </span>
                            <div data-v-429ecedc className="dialogBody transactionPwdBody" bis_skin_checked={1}>
                                <form onSubmit={handleSubmit(onSubmitPass2)} data-v-429ecedc className="el-form el-form--label-top">
                                    <div data-v-429ecedc className="el-form-item el-form-item--mini" bis_skin_checked={1}>
                                        <label htmlFor="transactionPwd" className="el-form-item__label">
                                            Mật khẩu giao dịch cũ
                                        </label>
                                        <div className="el-form-item__content" bis_skin_checked={1}>
                                            <div data-v-429ecedc className="ruleFormInput el-input el-input--mini el-input--suffix" bis_skin_checked={1}>
                                                <input {...register("oldpassword")} type="password" autoComplete="off" placeholder="Mật khẩu rút tiền" className="el-input__inner" />
                                            </div>
                                        </div>
                                    </div>
                                    <div data-v-429ecedc className="el-form-item el-form-item--mini" bis_skin_checked={1}>
                                        <label htmlFor="newTransactionPwd" className="el-form-item__label">
                                            Mật khẩu giao dịch mới
                                        </label>
                                        <div className="el-form-item__content" bis_skin_checked={1}>
                                            <div data-v-429ecedc className="ruleFormInput el-input el-input--mini el-input--suffix" bis_skin_checked={1}>
                                                <input {...register("password")} type="password" autoComplete="off" placeholder="Mật khẩu rút tiền" className="el-input__inner" />
                                            </div>
                                        </div>
                                    </div>
                                    <div data-v-429ecedc className="el-form-item el-form-item--mini" bis_skin_checked={1}>
                                        <label htmlFor="newTransactionPwd2" className="el-form-item__label">
                                            Xác nhận lại mật khẩu giao dịch
                                        </label>
                                        <div className="el-form-item__content" bis_skin_checked={1}>
                                            <div data-v-429ecedc className="ruleFormInput el-input el-input--mini el-input--suffix" bis_skin_checked={1}>
                                                <input {...register("checkpassword")} type="password" autoComplete="off" placeholder="Mật khẩu rút tiền" className="el-input__inner" />
                                            </div>
                                        </div>
                                    </div>
                                    <button data-v-429ecedc type="submit" className="el-button el-button--primary el-button--small">
                                        <span>Xác nhận thay đổi</span>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="v-modal" tabIndex={0} style={{ zIndex: 2008 }} bis_skin_checked={1} />
            </div>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired
};

export default function EditPayPassword() {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(emails[1]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };

    return (
        <React.Fragment>
            {/* <Link
                to="/register"
                className="btn btn-register"
                onClick={(e) => {
                    e.preventDefault();
                    handleClickOpen();
                }}>
                Đăng ký
            </Link> */}
            <button
                onClick={(e) => {
                    e.preventDefault();
                    handleClickOpen();
                }}
                data-v-429ecedc
                type="button"
                className="el-button ml24 w124 el-button--primary el-button--mini">
                <span>Cài đặt</span>
            </button>
            <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
        </React.Fragment>
    );
}
