import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const ComponentLogin = ({setIsSignin, handleClose}) => {
    const [err, setErr] = useState(null);
    const login = localStorage.getItem("user");
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    useEffect(() => {
        if (login) {
            navigate("/");
            handleClose()
        }
    }, []);
    const onSubmit = async (data) => {
        const regex = /^[a-zA-Z0-9]+$/;
        if (data.username.length < 6) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập tối thiểu 6 kí tự"
            });
            return;
        }
        if (!regex.test(data.username)) {
            setError("username", {
                type: "minLength",
                message: "Tên đăng nhập không chứa khoảng trắng và ký tự đặc biệt"
            });
            return;
        }
        if (data.password.length < 6) {
            setError("password", {
                type: "minLength",
                message: "Mật khẩu tối thiểu 6 kí tự"
            });
            return;
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/login`, {
                username: data.username.toLowerCase(),
                password: data.password
            })
            .then((res) => {
                swal({
                    title: "Thông báo",
                    text: "Đăng nhập thành công",
                    icon: "success",
                    buttons: "OK"
                }).then(() => {
                    localStorage.removeItem("user");
                    localStorage.removeItem("profile");
                    localStorage.removeItem("data");
                    localStorage.setItem("user", res.data.data);
                    handleClose()
                    navigate("/");
                });
            })
            .catch((err) => setErr(err.response.data.message ? err.response.data.message : "Tên đăng nhập hoặc mật khẩu không chính xác!"));
    };
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };
    return (
        <>
            <div className="el-dialog__body" bis_skin_checked={1}>
                <div data-v-0c5ed362 className="formContainer" bis_skin_checked={1}>
                    {/**/}
                    <form onSubmit={handleSubmit(onSubmit)} data-v-0c5ed362 className="el-form vnForm el-form--label-top">
                        <div data-v-0c5ed362 className="el-form-item el-form-item--feedback" bis_skin_checked={1}>
                            {/**/}
                            <div className="el-form-item__content" bis_skin_checked={1}>
                                <span data-v-0c5ed362 style={{ float: "left" }}>
                                    Tài khoản
                                </span>
                                <div data-v-0c5ed362 className="el-input el-input--prefix" bis_skin_checked={1}>
                                    {/**/}
                                    <input type="text" {...register("username")} autoComplete="off" placeholder="Tài khoản" className="el-input__inner" />
                                    <span className="el-input__prefix">
                                        <i data-v-0c5ed362 className="icon icon-account" />
                                        {/**/}
                                    </span>
                                    {/**/}
                                    {/**/}
                                    {/**/}
                                </div>
                                {/**/}
                            </div>
                        </div>
                        {errors.username ? <p>{errors.username.message}</p> : null}
                        <br />
                        <div data-v-0c5ed362 className="el-form-item el-form-item--feedback" bis_skin_checked={1}>
                            {/**/}
                            <div className="el-form-item__content" bis_skin_checked={1}>
                                <span data-v-0c5ed362 style={{ float: "left" }}>
                                    mật khẩu
                                </span>
                                <div data-v-0c5ed362 className="el-input el-input--prefix" bis_skin_checked={1}>
                                    {/**/}
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        {...register("password")}
                                        autoComplete="off"
                                        placeholder="6-15 ký tự chữ và số  "
                                        className="el-input__inner"
                                    />
                                    <span className="el-input__prefix">
                                        <i data-v-0c5ed362 className="icon icon-keywords" />
                                        {/**/}
                                    </span>
                                    {/**/}
                                    {/**/}
                                    {/**/}
                                </div>
                                {/**/}
                            </div>
                        </div>
                        {errors.password ? <p>{errors.password.message}</p> : null}
                        {/**/}
                        <div data-v-0c5ed362 className="links" bis_skin_checked={1}>
                            <div data-v-0c5ed362 className="el-form-item el-form-item--feedback" bis_skin_checked={1} style={{ marginTop: "-10px", marginBottom: "-5px" }}>
                                {/**/}
                                <div className="el-form-item__content" bis_skin_checked={1}>
                                    <label data-v-0c5ed362 className="el-checkbox" style={{ color: "rgb(160, 160, 160)", marginTop: "-10px" }}>
                                        <span className="el-checkbox__input">
                                            <span className="el-checkbox__inner" />
                                            <input type="checkbox" aria-hidden="false" className="el-checkbox__original" defaultValue />
                                        </span>
                                        <span className="el-checkbox__label">Nhớ mật khẩu {/**/}</span>
                                    </label>
                                    {/**/}
                                </div>
                            </div>
                        </div>
                        <div className="el-dialog__footer" bis_skin_checked={1}>
                            <span data-v-0c5ed362 className="dialog-footer">
                                <button data-v-0c5ed362 type="submit" className="el-button loginBtn el-button--primary">
                                    {/**/}
                                    {/**/}
                                    <span>Đăng nhập</span>
                                </button>

                                <div data-v-0c5ed362 style={{ marginTop: "0px", marginLeft: "5px", paddingBottom: "20px", fontSize: "14px" }} bis_skin_checked={1}>
                                    <div
                                        onClick={()=> {
                                            setIsSignin(false)
                                        }}
                                        data-v-0c5ed362
                                        type="primary"
                                        className="registerBtn"
                                        style={{
                                            width: "330px",
                                            height: "38px",
                                            lineHeight: "38px",
                                            cursor: "pointer",
                                            color: "rgb(144, 162, 220)",
                                            border: "1px solid rgb(144, 162, 220)",
                                            borderRadius: "4px"
                                        }}
                                        bis_skin_checked={1}>
                                        {" "}
                                        Đăng ký{" "}
                                    </div>
                                </div>
                            </span>
                        </div>
                    </form>
                    {/**/}
                </div>
            </div>
        </>
    );
};

export default ComponentLogin;
